/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Flex,
  Image,
  Text,
  useToast,
} from '@chakra-ui/react';
import {css} from '@emotion/react';
import {useEffect, useRef, useState} from 'react';
import {RiSearchLine} from 'react-icons/ri';
import {useNavigate, useParams} from 'react-router-dom';

import {Main} from '../../components/Main';
import {localStorageKeys} from '../../constants/storageKeys';
import {theme} from '../../constants/theme';
import {fontsCSS} from '../../helpers/css';
import {genArray} from '../../helpers/genArray';
import {postVipList} from '../../helpers/requestApi/xhtFiledSettingController';
import {useGetUserInfo} from '../../hooks/useGetUserInfo';
import {
  MemberItem,
  MemberItemAvatar,
  MemberList,
  SearchContainer,
  SearchIcon,
  SearchInput,
  TabNavItem,
  Tabs,
} from './components';
import {navItemScroll} from './helpers';

export function AddressBookPage() {
  const [input, setInput] = useState('');
  const [list, setList] = useState([]);
  const params = useParams('all');
  const scrollEffectRef = useRef(null);
  const toast = useToast();

  const {vipStatus} = useGetUserInfo();
  const navigate = useNavigate();

  // function scrollEffectRef(dom, id) {
  //   console.log(dom, id);
  // }
  function handleClickItem(item) {
    if (vipStatus >= 5) navigate(`/address-book/detail/${item.vipId}`);
    else
      toast({
        description: '成为会员后，才可查看详情！',
        duration: 3000,
        position: 'top',
        status: 'warning',
      });
  }
  useEffect(() => {
    postVipList({
      siteId: localStorage.getItem(localStorageKeys.SITE_ID),
    }).then((res) => {
      // res[0].vips[0].vipMemberImage =
      //   'http://investment.qh-1.cn/file/group1/M00/00/0A/CtweZGI9fryAJgRTAAQUacCj0Y4020.png';
      setList(res || []);
    });
  }, []);
  useEffect(() => {
    const children = scrollEffectRef.current.children;
    if (children[params.menuId]) navItemScroll(children[params.menuId]);
    else if (children[0]) navItemScroll(children[0]);
  }, [params.menuId, list.length]);

  return (
    <Main className='AddressBookPage'>
      <Box position='sticky' top={0} zIndex={1}>
        <Box bg='white' px='15px' py='10px'>
          <SearchContainer>
            <SearchInput
              placeholder='搜索会员'
              value={input}
              onChange={(ev) => setInput(ev.target.value)}
            />
            <SearchIcon>
              <RiSearchLine size={18} />
            </SearchIcon>
          </SearchContainer>
        </Box>

        <Tabs bg='white'>
          <TabNavItem to='/address-book/all'>全部</TabNavItem>
          {list.map((item, index) => (
            <TabNavItem key={index} to={`/address-book/${index}`}>
              {item.levelName}
            </TabNavItem>
          ))}
        </Tabs>
      </Box>

      <Accordion
        allowMultiple
        backgroundColor={theme.colors.BLOCK}
        defaultIndex={genArray(99)}
        mt={2}
        ref={scrollEffectRef}
      >
        {list.map((n) => (
          <AccordionItem border='none' key={n.levelId}>
            {n?.vips?.length !== 0 && (
              <AccordionButton backgroundColor='#F1F1F1'>
                <Box flex='1' textAlign='left'>
                  {n.levelName}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            )}
            <AccordionPanel pb={4}>
              <MemberList>
                {n?.vips.map(
                  (o, index) =>
                    o.vipName?.includes(input) && (
                      <MemberItem
                        key={index}
                        onClick={() => {
                          handleClickItem(o);
                        }}
                      >
                        {o.wxPhoto ? (
                          <Image
                            css={css`
                              border-radius: 10px;
                              margin-right: 6px;
                            `}
                            height={45}
                            src={o.wxPhoto}
                            width={45}
                          />
                        ) : (
                          <MemberItemAvatar />
                        )}
                        <Box>
                          <Flex alignItems='center' gap={2}>
                            <Text
                              css={css`
                                ${fontsCSS.medium}
                              `}
                            >
                              {o.vipName}
                            </Text>

                            {o.labels &&
                              o.labels.split(',').map((p, k) => {
                                return <Badge key={k}>{p}</Badge>;
                              })}
                          </Flex>
                          <Text color='#8E8E93'>{o.orgName}</Text>
                        </Box>
                        <Box
                          css={css`
                            ${fontsCSS.medium}
                          `}
                          ml='auto'
                        >
                          <Text color='#2D6DAD'>{o.vipJob}</Text>
                        </Box>
                      </MemberItem>
                    )
                )}
              </MemberList>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Main>
  );
}
