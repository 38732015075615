import {Global as GlobalStyle} from '@emotion/react';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {AboutPage} from './containers/AboutPage';
import {ActivityApplyPage} from './containers/ActivityApplyPage';
import {ActivityDetailPage} from './containers/ActivityDetailPage';
import {ActivityPage} from './containers/ActivityPage';
import {ActivitySignInPage} from './containers/ActivitySignInPage';
import {AddressBookDetailPage} from './containers/AddressBookDetailPage';
import {AddressBookPage} from './containers/AddressBookPage';
import {ApplicationAgreementPage} from './containers/ApplicationAgreementPage';
import {ApplicationPage} from './containers/ApplicationPage';
import {ContactPage} from './containers/ContactPage';
import {HomePage} from './containers/HomePage';
import {Layout} from './containers/Layout';
import {LoginPage} from './containers/LoginPage';
import {MenuPage} from './containers/MenuPage';
import {NotFoundPage} from './containers/NotFoundPage';
import {StatusPage} from './containers/StatusPage';
import {UserFeeIndexPage} from './containers/UserFee/UserFeeIndexPage';
import {UserFeeInfoPage} from './containers/UserFee/UserFeeInfoPage';
import {UserFeePublicPage} from './containers/UserFee/UserFeePublicPage';
import {UserFeeUpload} from './containers/UserFee/UserFeeUpload';
import {UserNotificationPage} from './containers/UserNotificationPage';
import {UserPage} from './containers/UserPage';
import {UserProfileEditPage} from './containers/UserProfileEditPage';
import {UserProfilePage} from './containers/UserProfilePage';
import {electronOnly, layout, rebase, themeing} from './global.css';

function App() {
  return (
    <>
      {/* register your routes */}
      <Routes>
        <Route element={<Layout />} path='/'>
          <Route index element={<HomePage />} />

          <Route element={<AddressBookPage />} path='/address-book/:menuId' />
          <Route
            element={<Navigate replace to='/address-book/all' />}
            path='/address-book'
          />

          <Route element={<UserPage />} path='/user' />
        </Route>

        {/* 活动列表 */}
        <Route element={<ActivityPage />} path='/activity/:type' />

        {/* 首页 */}
        <Route element={<MenuPage />} path='/menu/:menuType' />

        {/* 活动详情 */}
        <Route
          element={<ActivityDetailPage />}
          path='/activity/detail/:activityId'
        />

        {/* 提交报名信息 */}
        <Route
          element={<ActivityApplyPage />}
          path='/activity/apply/:activityId'
        />

        {/* 提交签到信息 */}
        <Route
          element={<ActivitySignInPage />}
          path='/activity/sign-in/:activityId'
        />

        {/* 会员详情 */}
        <Route
          element={<AddressBookDetailPage />}
          path='/address-book/detail/:vipId'
        />

        {/* 申请入会 */}
        <Route element={<ApplicationPage />} path='/application' />
        <Route
          element={<ApplicationAgreementPage />}
          path='/application-agreement'
        />

        {/* 我的 */}
        <Route element={<UserNotificationPage />} path='/user/notification' />
        <Route element={<UserProfilePage />} path='/user/profile' />
        <Route element={<UserProfileEditPage />} path='/user/profile/edit' />
        {/* 我的-会费缴纳-缴费主页 */}
        <Route element={<UserFeeIndexPage />} path='/user/fee/index' />
        {/* 我的-会费缴纳-缴费详情 */}
        <Route element={<UserFeeInfoPage />} path='/user/fee/info' />
        {/* 我的-会费缴纳-公对公转账 */}
        <Route element={<UserFeePublicPage />} path='/user/fee/public' />
        {/* 我的-会费缴纳-上传缴费截图 */}
        <Route element={<UserFeeUpload />} path='/user/fee/upload' />
        {/* 关于协会 */}
        <Route element={<AboutPage />} path='/about' />
        {/* 联系我们 */}
        <Route element={<ContactPage />} path='/contact' />
        <Route element={<LoginPage />} path='/login' />
        <Route element={<StatusPage />} path='/common-status' />
        <Route element={<NotFoundPage />} path='*' />
      </Routes>

      {/* register your global styles */}
      <GlobalStyle styles={[rebase, layout, themeing, electronOnly]} />
    </>
  );
}

export default App;
