import {useEffect, useState} from 'react';

import {sessionStorageKeys} from '../constants/storageKeys';
import {getSiteTitle} from '../helpers/requestApi/userController';

// write your custom hooks e.g.
export function useGetSiteInfo() {
  const [siteTitle, setSiteTitle] = useState(
    // getSessionStorage(sessionStorageKeys.SITE_TITLE)
    sessionStorage.getItem(sessionStorageKeys.SITE_TITLE)
  );
  useEffect(() => {
    if (!siteTitle)
      getSiteTitle().then((res) => {
        setSiteTitle(res || '');
        sessionStorage.setItem(sessionStorageKeys.SITE_TITLE, res);
      });
  }, [siteTitle]);

  return {
    siteTitle,
  };
}
