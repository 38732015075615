import {Flex} from '@chakra-ui/react';
import styled from '@emotion/styled';

import {theme} from '../../constants/theme';

export const Banner = styled.div`
  height: 172px;
  width: 100%;
  background-color: lightgray;
  border-radius: 5px 5px 0px 0px;
`;

export const DetailCard = styled.div`
  background-color: #fff;
  padding: 15px 10px 15px 15px;
  border-radius: 0px 0px 4px 4px;
  user-select: auto;
`;

export const DetailTitle = styled.div`
  font-size: 15px;
  color: #333333;
  line-height: 21px;
`;

export const InfoText = styled.div`
  font-size: 15px;
  color: #666666;
  line-height: 21px;
`;

export const TimeBox = styled(Flex)`
  width: 324px;
  height: 34px;
  background: rgba(255, 149, 0, 0.1);
  border-radius: 17px;
  height: 34px;
  width: 100%;
  padding: 0 10px;
  color: #757575;
  margin-top: 11px;
  font-size: 13px;
`;

export const TimeLeft = styled(Flex)``;
export const TimeRight = styled.div``;

export const TimeAddressBox = styled.div`
  border-top: 1px solid ${theme.colors.BORDER};
  margin-top: 15px;
  color: #666666;
  font-size: 15px;
`;

export const Orangle = styled.div`
  font-size: 12px;
  color: #ff9500;
  display: inline-block;
`;

export const Activity = styled.div`
  padding: 13px 15px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 4px;
  user-select: auto;
`;

export const ActivityTitle = styled.div`
  font-size: 17px;
  color: #333333;
  font-weight: 600;
`;

export const ActivityContent = styled.div`
  margin-top: 10px;
  user-select: auto;
  img {
    max-width: 100% !important;
  }
`;

export const ActivityFooter = styled.div`
  height: 40px;
  width: 100%;
`;
