import {SkeletonText} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState, useEffect} from 'react';

import {images} from '../../assets';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {getLocalStorage} from '../../constants/storageKeys';
import {theme} from '../../constants/theme';
import {postAboutList} from '../../helpers/requestApi/xhtFiledSettingController';
import {Container} from '../Layout/components';
const AboutContent = styled.div`
  /* flex: 1; */
  height: calc(100% - 60px);
  overflow-y: auto;
  padding: 10px;
`;
const AboutList = styled.div`
  width: 100vw;
  overflow-x: auto;
  display: flex;
  background-color: #fff;
  font-size: 16px;
  color: #a2a2a2;
`;
const AboutItem = styled.div`
  height: 60px;
  line-height: 60px;
  flex-shrink: 0; /* 用数值来收缩比例 默认值为1 */
  padding: 0 30px;
  text-align: center;
  font-size: 16px;
`;
const activeCss = css`
  color: ${theme.colors.PRIMARY};
  background: url(${images.aboutPage.tabBg}) no-repeat top right / 100% 100%;
`;
export function AboutPage() {
  const [loading, setLoading] = useState(true);
  const [aboutList, setAboutList] = useState([]);
  const [navTitle, setNavTitle] = useState('协会简介');
  const [aboutContent, setAboutContent] = useState('');
  useEffect(() => {
    postAboutList({
      siteId: getLocalStorage('SITE_ID'),
    }).then((res) => {
      setAboutList(res || []);
      if (res && res.length) {
        setNavTitle(res[0].aboutTitle);
        setAboutContent(res[0].aboutContent);
      }
      // setTimeout(() => {
      setLoading(false);
      // }, 3000);
    });
  }, []);
  function clickItem(item) {
    setNavTitle(item.aboutTitle);
    setAboutContent(item.aboutContent);
  }
  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>{navTitle}</NavTitle>
      </Navigation>
      <SkeletonText isLoaded={!loading} noOfLines={10}>
        <Main
          className='AboutPage'
          css={css`
            background: #fff;
            display: flex;
            flex-direction: column;
            height: calc(100vh - 50px);
            user-select: auto;
          `}
        >
          <AboutContent dangerouslySetInnerHTML={{__html: aboutContent}} />
          <AboutList>
            {aboutList.map((item) => {
              return (
                <AboutItem
                  css={item.aboutTitle === navTitle ? activeCss : ''}
                  key={item.rid}
                  onClick={() => {
                    clickItem(item);
                  }}
                >
                  {item.aboutTitle}
                </AboutItem>
              );
            })}
          </AboutList>
        </Main>
      </SkeletonText>
    </Container>
  );
}
