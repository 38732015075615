import {Box, Button, Text, VStack} from '@chakra-ui/react';
import styled from '@emotion/styled';

import {theme} from '../../constants/theme';
import {fontsCSS} from '../../helpers/css';

export const Container = styled.article`
  padding: 0 15px;
  margin-top: -30px;
`;

export const ProfileBlock = styled(VStack)`
  background: #ffffff;
  border-radius: 4px;
  padding: 17px 15px;
  align-items: unset;
`;

export const ProfileGroup = styled(Box)``;

export const ProfileTitle = styled.p`
  font-size: 15px;
  ${fontsCSS.semibold}
  color: #333333;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &::before {
    content: '';
    display: block;
    width: 3px;
    height: 12px;
    background: #da271d;
    margin-right: 4px;
  }
`;

export const ProfileField = styled(Text)`
  background: ${theme.colors.blacks[4]};
  border-radius: 2px;
  font-size: 14px;
  color: #333333;
  padding: 10px 7px;
`;

export const ProfileContent = styled.p`
  font-size: 15px;
  color: #333333;
  line-height: 21px;
`;

export const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #2d6dad;
  border-radius: 2px;
  color: white;
  width: 100%;
  font-size: 15px;
`;

export const IconTextBox = styled.div`
  padding: 4px 17px 0 17px;
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

export const IconText = styled.div`
  display: flex;
  padding: 12px 0;
  align-items: center;
  font-size: 14px;
  /* gap: 6; */
  border-bottom: 1px solid ${theme.colors.BORDER};
`;

export const TextBox = styled.div`
  flex: 1;
  font-size: 14px;
  margin-left: 2px;
`;

export const BtnBox = styled(Button)`
  height: 26px;
  border-radius: 2px;
  padding: 3px 8px;
  font-size: 14px;
  border: 1px solid ${theme.colors.PRIMARY};
  color: ${theme.colors.PRIMARY};
  background-color: #fff !important;
  margin-left: 2px;
`;
