// write your constant variables e.g.
// export const BASE_URL = process.env.REACT_APP_BASE_URL ?? '/api'

// 默认接口地址
export const BASE_URL = process.env.REACT_APP_API_URL ?? '/companyApp';

export const ENV_URL =
  location.host === 'xht.tosilu.cn'
    ? 'https://xht.tosilu.cn'
    : 'https://xht-dev.tosilu.cn';

export const BASE_PRIVATE_URL = `${ENV_URL}/companyApp/common/readSecretFile?path=`; // 私密文件路径前缀
