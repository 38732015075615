import {SkeletonText} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState, useEffect} from 'react';

import {images} from '../../assets';
import {Image} from '../../components/Image';
import {getLocalStorage} from '../../constants/storageKeys';
import {getSettingInfo} from '../../helpers/requestApi/xhtFiledSettingController';
import {getVipInfo} from '../../helpers/requestApi/xhtOrgVipController';
const TopCard = styled.div`
  padding-top: 10px;
  background-color: #111;
  position: relative;
`;
const Card = styled.div`
  height: 200px;
  margin-left: 15px;
  position: relative;
`;
const LevelName = styled.div`
  position: absolute;
  right: 0;
  font-size: 12px;
  color: #fff;
  height: 31px;
  line-height: 31px;
  padding-left: 12%;
`;

const CardBottom = styled.div`
  padding-top: 48px;
  padding-left: 20px;
  display: flex;
`;
const LeftLogo = styled.div`
  width: 44px;
  height: 44px;
  background: #fff;
  border-radius: 50%;
  margin-right: 4px;
  overflow: hidden;
`;
const Logo = styled(Image)`
  width: 100%;
  height: 100%;
`;

const RightContent = styled.div`
  font-size: 12px;
`;
const LevelNameText = styled.span`
  color: #eed8b0;
  margin-right: 8px;
`;
const CardP = styled.p`
  font-size: 12px;
`;
const CardStrong = styled.strong`
  font-size: 17px;
  margin-right: 6px;
`;

const cardTypes = /** @type {const} */ {
  card1: {
    key: 'card1',
    cardBg: images.user.cardHuizhang,
    levelBg: images.user.levelHuizhang,
    color: '#5C2C02',
  },
  card2: {
    key: 'card2',
    cardBg: images.user.cardFuhuizhang,
    levelBg: images.user.levelFuhuizhang,
    color: '#3B3950',
  },
  card3: {
    key: 'card3',
    cardBg: images.user.cardLishi,
    levelBg: images.user.levelLishi,
    color: '#501F13',
  },
  card4: {
    key: 'card4',
    cardBg: images.user.cardHuiyuan,
    levelBg: images.user.levelHuiyuan,
    color: 'white',
  },
};
export function ProfileCard({getUserInfo, vipId, userApi}) {
  // const currentType = cardTypes[cardType];
  const [loading, setLoading] = useState(true);
  const [currentType, setCurrentType] = useState({
    key: '',
    cardBg: 'card4',
    levelBg: '',
    levelText: '',
    color: '',
  });
  const [cardInfo, setCardInfo] = useState({
    enterDate: '2022-03-09',
    expireDate: '2022-03-09',
    legalPerson: 'string',
    orgName: 'string',
    vipJob: 'string',
    vipName: 'string',
    vipNum: 'string',
    levels: [
      {
        levelBottomImg: 'string',
        levelName: 'string',
        rid: 0,
        vipId: 0,
        vipLevel: 0,
      },
    ],
  });
  useEffect(() => {
    const request1 = getSettingInfo({
      siteId: getLocalStorage('SITE_ID'),
    });

    const request2Api = userApi || getVipInfo;
    const request2 = request2Api({
      siteId: getLocalStorage('SITE_ID'),
      vipId: vipId || undefined,
    });

    Promise.all([request1, request2]).then(([data, res]) => {
      setLoading(false);
      if (getUserInfo) getUserInfo(res);
      const obj = {
        ...cardInfo,
      };
      for (const key in cardInfo) {
        if (Object.hasOwnProperty.call(cardInfo, key)) {
          obj[key] = res[key];
        }
      }
      if (res.levels && res.levels[0]) {
        setCurrentType(cardTypes[res.levels[0].levelBottomImg || 'card4']);
        obj.levelText = res.levels[0].levelName;
      }
      obj.iconLogo = data.associationSpecialImg;

      setCardInfo(obj);
    });
  }, []);
  return (
    <TopCard>
      <Card
        css={css`
          background: url(${currentType.cardBg}) no-repeat right top / 100% 100%;
          color: ${currentType.color};
        `}
      >
        <LevelName
          css={css`
            background: url(${currentType.levelBg}) no-repeat right top / 100%
              100%;
          `}
        >
          会员等级：
          <LevelNameText>{cardInfo.levelText}</LevelNameText>
        </LevelName>
        <CardBottom>
          <LeftLogo>
            <Logo objectFit='cover' src={cardInfo.iconLogo} />
          </LeftLogo>
          <SkeletonText isLoaded={!loading}>
            <RightContent>
              <CardP
                css={css`
                  font-size: 13px;
                `}
              >
                <CardStrong>{cardInfo.vipName}</CardStrong>
                {cardInfo.vipJob}
              </CardP>
              <CardP
                css={css`
                  font-size: 15px;
                  margin-bottom: 40px;
                `}
              >
                {cardInfo.orgName}
              </CardP>
              <CardP
                css={css`
                  margin-bottom: 5px;
                `}
              >
                会籍编号：{cardInfo.vipNum}
              </CardP>
              <CardP>
                入会有效期：{cardInfo.enterDate}-{cardInfo.expireDate}
              </CardP>
            </RightContent>
          </SkeletonText>
        </CardBottom>
      </Card>
    </TopCard>
  );
}
