import {ENV_URL} from '../../constants/url';

const {request} = require('../request');

// 下载入会申请模板
export function getApplyExport(data) {
  return `${ENV_URL}/companyApp/api/xht/apply/export?type=DOC&data=${data}`;
}

// 入会申请下载校验
export function exportValidate(data) {
  return request.post(`/api/xht/apply/export/validate`, data);
}
