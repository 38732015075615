import {useRef} from 'react';

// 1秒点击多次钩子
function useRepeatedlyClick(tigger) {
  const countRef = useRef(0);

  const clickEvent = () => {
    countRef.current++;
    if (countRef.current >= 5) {
      tigger();
      countRef.current = 0;
    }
    setTimeout(() => {
      countRef.current = 0;
    }, 1000);
  };

  return {clickEvent};
}

export default useRepeatedlyClick;
