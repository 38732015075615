import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {images} from '../../assets';
import {getSiteConfig} from '../../helpers/requestApi/userController';
import {useGetUserInfo} from '../../hooks/useGetUserInfo';
import {
  Container,
  Tabbar,
  TabbarButton,
  TabbarButtonIcon,
  TabbarButtonText,
} from './components';

const defaultTabbars = {
  HOME: {
    key: 'HOME',
    icon: images.tabbar.iconHome,
    iconActive: images.tabbar.iconHomeActive,
    name: '首页',
    path: '/',
  },
  ADDRESS_BOOK: {
    key: 'ADDRESS_BOOK',
    icon: images.tabbar.iconAddressBook,
    iconActive: images.tabbar.iconAddressBookActive,
    name: '通讯录',
    path: '/address-book',
  },
  USER: {
    key: 'USER',
    icon: images.tabbar.iconUser,
    iconActive: images.tabbar.iconUserActive,
    name: '我的',
    path: '/user',
  },
};

export function Layout() {
  const {pathname} = useLocation();

  const checkHomePath = (path) => (path === '/' ? pathname === '/' : true);

  const navigate = useNavigate();
  const {isOpen, onOpen, onClose} = useDisclosure();

  const {vipStatus} = useGetUserInfo();

  // eslint-disable-next-line no-unused-vars
  const [tabbars, setTabbars] = useState(defaultTabbars);

  function handleClickYes() {
    if (vipStatus < 1) navigate('/application');
    else navigate('/common-status');
  }

  function navigateTo(item) {
    if (item.key === tabbars.USER.key && vipStatus < 6) onOpen();
    else navigate(item.path);
  }

  useEffect(() => {
    getSiteConfig().then((res) => {
      console.log(res);

      // setTabbars([]);
    });
  }, []);

  return (
    <Container className='Layout'>
      <Outlet />

      <Tabbar style={{marginTop: 'auto'}}>
        {Object.values(tabbars).map((tabbar) => (
          <TabbarButton
            key={tabbar.key}
            onClick={() => {
              navigateTo(tabbar);
            }}
          >
            {pathname.startsWith(tabbar.path) && checkHomePath(tabbar.path) ? (
              <TabbarButtonIcon src={tabbar.iconActive} />
            ) : (
              <TabbarButtonIcon src={tabbar.icon} />
            )}
            <TabbarButtonText>{tabbar.name}</TabbarButtonText>
          </TabbarButton>
        ))}
      </Tabbar>

      <Modal isCentered isOpen={isOpen} size='xs' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>是否加入协会？</ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handleClickYes}>
              是
            </Button>
            <Button variant='ghost' onClick={onClose}>
              取消
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
}
