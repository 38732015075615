import styled from '@emotion/styled';
import {size} from 'polished';

import {Image} from '../../components/Image';
import {theme} from '../../constants/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Tabbar = styled.div`
  display: flex;
  justify-content: space-around;
  height: 72px;
  background-color: ${theme.colors.BLOCK};
`;

export const TabbarButton = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 33.3%;
  color: #c3c3c3;

  &.active {
    color: #3170ae;
  }
`;

export const TabbarButtonIcon = styled(Image)`
  ${size(24)}
`;

export const TabbarButtonText = styled.span`
  font-size: 12px;
  font-family: ${theme.fonts.PINGFANG_SEMIBOLD};
`;
