import {Box, Flex, Text, VStack} from '@chakra-ui/react';
import {useEffect, useState} from 'react';

import {images} from '../../assets';
import {Image} from '../../components/Image';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {localStorageKeys} from '../../constants/storageKeys';
import {theme} from '../../constants/theme';
import {getMsgList} from '../../helpers/requestApi/xhtMsgController';
import {Container} from '../Layout/components';

export function UserNotificationPage() {
  const [list, setList] = useState([]);

  useEffect(() => {
    getMsgList({
      page: 1,
      pageSize: 999,
      siteId: localStorage.getItem(localStorageKeys.SITE_ID),
    }).then((res) => {
      setList(res.list || []);
    });
  }, []);

  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>我的消息</NavTitle>
      </Navigation>

      <Main
        className='UserNotificationPage'
        style={{
          padding: 15,
        }}
      >
        <Box className='ads' height={120} mb={3}>
          <Image objectFit='cover' src={images.imageAds} />
        </Box>

        <VStack alignItems='unset'>
          {list.map((item, index) => {
            return (
              <Flex
                alignItems='center'
                bg={theme.colors.BLOCK}
                borderRadius={2}
                gap={3}
                key={index}
                p={4}
              >
                {/* <MemberItemAvatar /> */}
                <Box>
                  <Text>系统消息</Text>
                  <Text color='#999999'>{item.msgContent}</Text>
                </Box>
                {/* <Indicator ml='auto'>7</Indicator> */}
              </Flex>
            );
          })}
        </VStack>
      </Main>
    </Container>
  );
}
