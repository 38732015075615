import styled from '@emotion/styled';

import {theme} from '../constants/theme';

export const Button = styled.button((props) => ({
  width: props.width ?? props.size ?? '100%',
  height: props.height ?? props.size ?? '46px',
  margin: props.margin ?? 0,
  color: props.color ?? '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: props.borderRadius ?? '4px',
  border: props.border ?? '',
  fontSize: props.fontSize ?? '14px',
  background: props.disabled
    ? '#CECED1'
    : props.backgroundColor ?? theme.colors.PRIMARY,
}));
