const {request} = require('../request');

// 入会申请
export function postOrderAdd(data) {
  return request.post(`/xht/order/add`, data);
}

// 会员详情
export function postOrderGet(data) {
  return request.post(`/xht/order/get`, data);
}

// 获取当前用户的状态
export function getUserInfo(data) {
  return request.post(`/xht/order/userInfo`, data);
}
