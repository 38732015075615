import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  useToast,
} from '@chakra-ui/react';
import {useState} from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';

import {images} from '../../assets';
import {Button} from '../../components/Button';
import {Image} from '../../components/Image';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {getLocalStorage, localStorageKeys} from '../../constants/storageKeys';
import {postActivityApplyList} from '../../helpers/requestApi/xhtActivityController';
import {getVipInfo} from '../../helpers/requestApi/xhtOrgVipController';
import {ApplicationFormLabel} from '../ApplicationPage/components';
import {Container} from '../Layout/components';
import {
  FormList,
  InfoBox,
  InfoTitle,
  FormLeft,
  FormRight,
  FormTips,
  StepBox,
  StepText,
  ImageTextBox,
} from './components';

export function ActivityApplyPage() {
  const {
    register,
    formState: {errors},
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      vipName: '',
      vipJob: '',
      vipPhone: '',
      orgName: '',
    },
    mode: 'all',
  });

  // const [vipLevelId, setValueId] = useState('');
  const [vipId, setVipId] = useState('');

  const toast = useToast();

  const params = useParams();

  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const request1 = getVipInfo({
      siteId: getLocalStorage('SITE_ID'),
    });

    Promise.all([request1]).then((res) => {
      if (res[0]) {
        const keyMap = ['vipName', 'vipJob', 'vipPhone', 'orgName'];
        keyMap.forEach((key) => {
          setValue(key, res[0][key]);
        });
        setValue(
          'vipLevel',
          res[0].levels
            ?.map((item) => {
              return item.levelName;
            })
            ?.join(',')
        );

        // setValueId(res[0].levels[0]?.vipLevel);

        setVipId(res[0].vipId);
      }
    });
  }, [setValue]);

  function apply(__form) {
    const obj = {
      ...__form,
      siteId: localStorage.getItem(localStorageKeys.SITE_ID),
      vipId: vipId || undefined,
      // vipLevel: vipLevelId || undefined,
      activityId: params.activityId,
      applyType: 1,
    };

    postActivityApplyList(obj).then((res) => {
      if (!res?.err) {
        setIsSuccess(true);
        toast({
          description: '报名成功',
          duration: 3000,
          position: 'top',
          status: 'success',
        });
      }
    });
  }

  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>提交报名信息</NavTitle>
      </Navigation>
      <Main padding='10px 15px 26px'>
        <InfoBox>
          <Flex align='center' justifyContent='space-between'>
            <InfoTitle>报名人信息</InfoTitle>
            {/* <EditBtn>
              <Flex align='center' gap='5px'>
                <Image size={20} src={images.activity.edit} />
                <div>编辑</div>
              </Flex>
            </EditBtn> */}
          </Flex>

          <FormList>
            <FormControl isRequired isInvalid={errors.vipName} mb='15px'>
              <Flex align='center'>
                <FormLeft>
                  <ApplicationFormLabel
                    htmlFor='vipName'
                    style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                  >
                    姓名
                  </ApplicationFormLabel>
                </FormLeft>
                <FormRight>
                  {isSuccess ? (
                    watch('vipName')
                  ) : (
                    <Input
                      id='vipName'
                      placeholder='请输入中文姓名'
                      type='text'
                      {...register('vipName', {required: '请输入姓名'})}
                      style={{
                        backgroundColor: '#fbfbfb',
                        height: '45px',
                      }}
                    />
                  )}
                </FormRight>
              </Flex>
              {errors.vipName && (
                <FormErrorMessage style={{marginTop: '4px'}}>
                  <FormTips>{errors.vipName.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.vipJob} mb='15px'>
              <Flex align='center'>
                <FormLeft>
                  <ApplicationFormLabel
                    htmlFor='vipJob'
                    style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                  >
                    职务
                  </ApplicationFormLabel>
                </FormLeft>
                <FormRight>
                  {isSuccess ? (
                    watch('vipJob')
                  ) : (
                    <Input
                      id='vipJob'
                      placeholder='请输入职务'
                      type='text'
                      {...register('vipJob', {required: '请输入职务'})}
                      style={{
                        backgroundColor: '#fbfbfb',
                        height: '45px',
                      }}
                    />
                  )}
                </FormRight>
              </Flex>
              {errors.vipJob && (
                <FormErrorMessage style={{marginTop: '4px'}}>
                  <FormTips>{errors.vipJob.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.vipPhone} mb='15px'>
              <Flex align='center'>
                <FormLeft>
                  <ApplicationFormLabel
                    htmlFor='vipPhone'
                    style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                  >
                    联系方式
                  </ApplicationFormLabel>
                </FormLeft>
                <FormRight>
                  {isSuccess ? (
                    watch('vipPhone')
                  ) : (
                    <Input
                      id='vipPhone'
                      placeholder='请输入联系方式'
                      type='text'
                      {...register('vipPhone', {required: '请输入联系方式'})}
                      style={{
                        backgroundColor: '#fbfbfb',
                        height: '45px',
                      }}
                    />
                  )}
                </FormRight>
              </Flex>
              {errors.vipPhone && (
                <FormErrorMessage style={{marginTop: '4px'}}>
                  <FormTips>{errors.vipPhone.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.orgName} mb='15px'>
              <Flex align='center'>
                <FormLeft>
                  <ApplicationFormLabel
                    htmlFor='orgName'
                    style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                  >
                    单位
                  </ApplicationFormLabel>
                </FormLeft>
                <FormRight>
                  {isSuccess ? (
                    watch('orgName')
                  ) : (
                    <Input
                      id='orgName'
                      placeholder='请输入单位'
                      type='text'
                      {...register('orgName', {required: '请输入单位'})}
                      style={{
                        backgroundColor: '#fbfbfb',
                        height: '45px',
                      }}
                    />
                  )}
                </FormRight>
              </Flex>
              {errors.orgName && (
                <FormErrorMessage style={{marginTop: '4px'}}>
                  <FormTips>{errors.orgName.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormControl>

            {watch('vipLevel') && (
              <FormControl mb='15px'>
                <Flex align='center'>
                  <FormLeft>
                    <ApplicationFormLabel
                      htmlFor='vipLevel'
                      style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                    >
                      会员等级
                    </ApplicationFormLabel>
                  </FormLeft>
                  <FormRight>{watch('vipLevel')}</FormRight>
                </Flex>
              </FormControl>
            )}
          </FormList>
        </InfoBox>

        <StepBox>
          <ImageTextBox>
            <Image size='70px' src={images.activity.step1} />
            <StepText>
              <p>填写信息</p>
              <p>提交订单</p>
            </StepText>
          </ImageTextBox>

          <ImageTextBox>
            <Image size='16px' src={images.activity.right} />
          </ImageTextBox>
          <ImageTextBox>
            <Image size='70px' src={images.activity.step2} />
            <StepText>
              <p>现场出示</p>
              <p>门票核销</p>
            </StepText>
          </ImageTextBox>
        </StepBox>
        {isSuccess ? (
          <Button
            margin='26px 0 0 0'
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        ) : (
          <Button margin='26px 0 0 0' onClick={handleSubmit(apply)}>
            提交报名
          </Button>
        )}
      </Main>
    </Container>
  );
}
