import wx from 'jweixin-module';
export function toLogin(params) {
  // alert(location.href);
  // console.log();
  wx.miniProgram.navigateTo({
    url: `/pages/wxAppletLogin/wxAppletLogin?redirect=${encodeURIComponent(
      location.href.split('?')[0]
    )}`,
  });
}

// ?redirect=${encodeURIComponent(
//   lg_back_url
// )}`
