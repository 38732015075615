import {rgba} from 'polished';

const colors = {
  PRIMARY: '#2D6DAD',
  TEXT: '#000D1A',
  SUBTEXT: '#828282',
  BACKGROUND: '#F1F5F9',
  BLOCK: '#FFF',
  BORDER: '#E6E6E6',
  get blacks() {
    const base = '#000D1A';
    return {
      base,
      90: rgba(base, 0.9),
      80: rgba(base, 0.8),
      70: rgba(base, 0.7),
      60: rgba(base, 0.6),
      50: rgba(base, 0.5),
      40: rgba(base, 0.4),
      30: rgba(base, 0.3),
      20: rgba(base, 0.2),
      10: rgba(base, 0.1),
      4: rgba(base, 0.04),
    };
  },
  get whites() {
    const base = '#FFFFFF';
    return {
      base,
      90: rgba(base, 0.9),
      80: rgba(base, 0.8),
      70: rgba(base, 0.7),
      60: rgba(base, 0.6),
      50: rgba(base, 0.5),
      40: rgba(base, 0.4),
      30: rgba(base, 0.3),
      20: rgba(base, 0.2),
      10: rgba(base, 0.1),
      4: rgba(base, 0.04),
    };
  },
};

const fonts = {
  PINGFANG_REGULAR: 'PingFangSC-Regular',
  PINGFANG_THIN: 'PingFangSC-Thin',
  PINGFANG_MEDIUM: 'PingFangSC-Medium',
  PINGFANG_SEMIBOLD: 'PingFangSC-Semibold',
};

export const theme = {
  colors,
  fonts,
};
