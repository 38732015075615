import wx from 'jweixin-module';
export function navigateToDownLoadFile(url) {
  wx.miniProgram.navigateTo({
    url: `/pages/downLoadFile/downLoadFile?url=${encodeURIComponent(url)}`,
  });
}

// export function wxShare() {
//   alert(wx.onMenuShareTimeline);
//   wx.onMenuShareTimeline({
//     title: '--{$info.name}', // 分享标题
//     link: '{$url}__URL__/index?pid={$pid}&puid={$uid}', // 分享链接,将当前登录用户转为puid,以便于发展下线
//     imgUrl: '{$url}__PUBLIC__/Uploads/{$goodsvo.image}', // 分享图标
//     success() {
//       // 用户确认分享后执行的回调函数
//       alert('分享成功');
//     },
//     cancel() {
//       // 用户取消分享后执行的回调函数
//     },
//   });
// }
