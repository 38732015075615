import imageChecking from './images/imageChecking.png';
import imageFail from './images/imageFail.png';
import imageSuccess from './images/imageSuccess.png';
import logo from './images/logo.png';
import logoOnly from './images/logoOnly.png';

export const images = {
  logo,
  logoOnly,
  imageSuccess,
  imageChecking,
  imageFail,
  imageAds: require('./images/imageAds.png'),
  tabbar: {
    iconHome: require('./images/tabbar/iconHome.png'),
    iconHomeActive: require('./images/tabbar/iconHomeActive.png'),
    iconAddressBook: require('./images/tabbar/iconAddressBook.png'),
    iconAddressBookActive: require('./images/tabbar/iconAddressBookActive.png'),
    iconUser: require('./images/tabbar/iconUser.png'),
    iconUserActive: require('./images/tabbar/iconUserActive.png'),
  },
  home: {
    bgBanner: require('./images/home/bgBanner.png'),
    iconLogo: require('./images/home/iconLogo.png'),
    iconMenu1: require('./images/home/iconMenu1.png'),
    iconMenu2: require('./images/home/iconMenu2.png'),
    iconMenu3: require('./images/home/iconMenu3.png'),
    iconMenu4: require('./images/home/iconMenu4.png'),
    iconMenu5: require('./images/home/iconMenu5.png'),
    iconMenu6: require('./images/home/iconMenu6.jpg'),
    bgTitleHotInfo: require('./images/home/bgTitleHotInfo.png'),
    bgTitleHotActivity: require('./images/home/bgTitleHotActivity.png'),
  },
  memberApply: {
    man: require('./images/memberApply/man.png'),
    file: require('./images/memberApply/file.png'),
    bg1: require('./images/memberApply/bg1.png'),
  },
  user: {
    cardHuizhang: require('./images/user/cardHuizhang.png'),
    cardFuhuizhang: require('./images/user/cardFuhuizhang.png'),
    cardLishi: require('./images/user/cardLishi.png'),
    cardHuiyuan: require('./images/user/cardHuiyuan.png'),
    levelHuizhang: require('./images/user/levelHuizhang.png'),
    levelFuhuizhang: require('./images/user/levelFuhuizhang.png'),
    levelLishi: require('./images/user/levelLishi.png'),
    levelHuiyuan: require('./images/user/levelHuiyuan.png'),
    huodong: require('./images/user/huodong.png'),
    xiaoxi: require('./images/user/xiaoxi.png'),
    profile: require('./images/user/profile.png'),
    progress: require('./images/user/progress.png'),
    contactUs: require('./images/user/contactUs.png'),
    fee: require('./images/user/fee.png'),
    userInfo: require('./images/user/userInfo.png'),
    circleRadius: require('./images/user/circleRadius.png'),
    iconRequired: require('./images/user/iconRequired.png'),
    feeBg: require('./images/user/fee-bg.png'),
    feeCardBg: require('./images/user/fee-card-bg.png'),
    feeCardBgIcon: require('./images/user/fee-card-bg-icon.png'),
    feeCardBgActive: require('./images/user/fee-card-bg-active.png'),
    feeCardBgActiveIcon: require('./images/user/fee-card-bg-active-icon.png'),
    feeNumberBg: require('./images/user/fee-number-bg.png'),
    feeNumberBorder: require('./images/user/fee-number-border.png'),
    accountBg: require('./images/user/account-bg.png'),
    step1: require('./images/user/step1.png'),
    step2: require('./images/user/step2.png'),
    step3: require('./images/user/step3.png'),
    accountInfoCard: require('./images/user/accountInfo-bg.png'),
    address: require('./images/user/address.png'),
    phone: require('./images/user/phone.png'),
  },
  contactUs: {
    bg: require('./images/contactUs/bg.png'),
    addressIcon: require('./images/contactUs/addressIcon.png'),
    phone: require('./images/contactUs/phone.png'),
  },
  aboutPage: {
    tabBg: require('./images/aboutPage/tabBg.png'),
  },
  activity: {
    time: require('./images/activity/time.png'),
    edit: require('./images/activity/edit.png'),
    step1: require('./images/activity/step1.png'),
    step2: require('./images/activity/step2.png'),
    right: require('./images/activity/right.png'),
  },
};

export const files = {};
