const {request} = require('../request');

// 字段设置列表
export function getFieldList(data) {
  return request.post(`/xht/setting/field/list`, data);
}

// 协会设置详情
export function getSettingInfo(data) {
  return request.post(`/xht/setting/get`, data);
}

// 协会等级列表带有会员信息
export function postVipList(data) {
  return request.post(`/xht/setting/level/vip-list`, data);
}
// 获取关于我们
export function postAboutList(data) {
  return request.post(`/xht/setting/about/list`, data);
}
