import {useClipboard, SkeletonText} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState, useEffect} from 'react';
import zmage from 'react-zmage';
import 'react-zmage/lib/zmage.css';

import {images} from '../../assets';
import {Image} from '../../components/Image';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {getLocalStorage} from '../../constants/storageKeys';
import {getSettingInfo} from '../../helpers/requestApi/xhtFiledSettingController';
import {addressSearch, phoneCall} from '../../helpers/tools';
import {useGetSiteInfo} from '../../hooks/useGetSiteInfo';
import {Container} from '../Layout/components';

export const AddressImg = styled(Image)`
  width: 26px;
  height: 26px;
  margin-right: 17px;
`;
export const PhoneImg = styled(Image)`
  width: 26px;
  height: 26px;
`;
export const ContactImg = styled(Image)`
  border: 1px solid #ededed;
  padding: 6px;
  width: 80px;
  height: 80px;
  margin: 20px auto 6px;
`;
const ContactTop = styled.div`
  color: #fff;
  padding: 29px 190px 40px 17px;
  font-size: 20px;
  background: url(${images.contactUs.bg}) no-repeat top right / 180px 140px;
  background-position: 217px 0;
`;
const ContactForm = styled.div`
  margin: 0 13px 0 15px;
  padding: 15px 10px 12px;
  background: #fff;
  transform: translateY(-20px);
  min-height: 400px;
  border-radius: 10px;
`;
const ContactPerson = styled.div`
  font-size: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f4f5f7;
`;
const ContactItem = styled.div`
  border-bottom: 1px solid #f4f5f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding: 17px 0;
`;
const Label = styled.div`
  color: #888888;
  width: 75px;
`;
const Value = styled.div`
  color: #333333;
  flex: 1;
`;
const Person = styled.div`
  color: #333333;
  flex: 1;
  position: relative;
  padding-right: 34px;
  &::after {
    position: absolute;
    top: 0;
    transform: translateX(3px);
    right: 15px;
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    border: 1px solid #cfcfcf;
  }
`;
const Copy = styled.div`
  width: 60px;
  padding: 3px 0;
  border-radius: 26px;
  border: 1px solid #2d6dad;
  color: #2d6dad;
  text-align: center;
`;
const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BlockBg = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f5f7;
  padding: 11px 15px 12px 12px;
  margin-top: 6px;
`;
export function ContactPage() {
  const {siteTitle} = useGetSiteInfo();
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [settingInfo, setSettingInfo] = useState({
    contactPerson: '',
    contactPhone: '',
    contactJob: '',
    contactEmail: '',
    contactAddress: '',
    contactImg: '',
  });
  const [copyKey, setCopyKey] = useState('contactEmail');
  const contactEmailCopy = useClipboard(settingInfo.contactEmail);
  const contactAddressCopy = useClipboard(settingInfo.contactAddress);

  function changeCopyValue(key) {
    setCopyKey(key);
    if (key === 'contactEmail') {
      contactEmailCopy.onCopy();
    } else {
      contactAddressCopy.onCopy();
    }
  }

  function handleClickPhoneCall(phone) {
    phoneCall(phone);
  }

  function handleClickAddress(address) {
    addressSearch(address);
  }

  useEffect(() => {
    if (loaded) return;
    getSettingInfo({
      siteId: getLocalStorage('SITE_ID'),
    }).then((res) => {
      setLoaded(true);
      const obj = {};
      Object.keys(settingInfo).forEach((key) => {
        obj[key] = res[key];
      });
      setSettingInfo(obj);
      setLoading(false);
    });
  }, [loaded, settingInfo]);
  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>联系我们</NavTitle>
      </Navigation>
      <Main
        className='ContactPage'
        css={css`
          background: #2d6dad;
        `}
      >
        <ContactTop>欢迎加入我们{siteTitle}</ContactTop>
        <ContactForm>
          <SkeletonText isLoaded={!loading} noOfLines={10}>
            <ContactPerson>
              <Block>
                <Value>联系人</Value>
              </Block>
              <BlockBg>
                {/* <a href={`tel:${settingInfo.contactPhone}`}> */}
                <Person>{settingInfo.contactPerson}</Person>
                {/* </a> */}
                <PhoneImg
                  src={images.contactUs.phone}
                  onClick={() => {
                    handleClickPhoneCall(settingInfo.contactPhone);
                  }}
                />
              </BlockBg>
            </ContactPerson>
            <ContactItem
              css={css`
                justify-content: flex-start;
              `}
            >
              <Label>联系电话</Label>
              <Value>{settingInfo.contactPhone}</Value>
            </ContactItem>
            <ContactItem
              css={css`
                justify-content: flex-start;
              `}
            >
              <Label>职务</Label>
              <Value>{settingInfo.contactJob}</Value>
            </ContactItem>
            <ContactItem>
              <Label>邮箱</Label>
              <Value>{settingInfo.contactEmail}</Value>
              <Copy
                onClick={() => {
                  changeCopyValue('contactEmail');
                }}
              >
                {contactEmailCopy.hasCopied && copyKey === 'contactEmail'
                  ? '已复制'
                  : '复制'}
              </Copy>
            </ContactItem>
            <ContactItem
              css={css`
                display: block;
              `}
            >
              <Block>
                <Label>地址</Label>
                <Value>&nbsp;</Value>
                <Copy
                  onClick={() => {
                    changeCopyValue('contactAddress');
                  }}
                >
                  {contactAddressCopy.hasCopied && copyKey === 'contactAddress'
                    ? '已复制'
                    : '复制'}
                </Copy>
              </Block>
              <Block
                css={css`
                  padding-top: 19px;
                `}
              >
                <Value>{settingInfo.contactAddress}</Value>
                <AddressImg
                  src={images.contactUs.addressIcon}
                  onClick={() => handleClickAddress(settingInfo.contactAddress)}
                />
              </Block>
            </ContactItem>
            <ContactImg
              src={settingInfo.contactImg}
              onClick={() => zmage.browsing({src: settingInfo.contactImg})}
            />
            <Label
              css={css`
                width: 100%;
                font-size: 14px;
                text-align: center;
                margin-bottom: 18px;
              `}
            >
              微信公众号
            </Label>
          </SkeletonText>
        </ContactForm>
      </Main>
    </Container>
  );
}
