import {images} from '../../assets';

export const STATUS_TEXT_MAP = {
  1: {
    title: '审核中',
    text: '您的申请表已经提交，请耐心等待！',
    img: images.imageChecking,
  },
  2: {
    title: '审核失败',
    text: '您提交的信息审核不通过，如有疑问请联系工作人员',
    img: images.imageFail,
  },
  3: {
    title: '审核通过',
    text: '您提交的信息审核通过，请尽快缴费',
    img: images.imageSuccess,
  },
  4: {
    title: '审核通过',
    text: '您提交的信息审核通过，请尽快缴费',
    img: images.imageSuccess,
  },
  5: {
    title: '审核通过',
    text: '您提交的信息审核通过，请尽快缴费',
    img: images.imageSuccess,
  },
  6: {
    title: '审核通过',
    text: '恭喜你入会成功',
    img: images.imageSuccess,
  },
  7: {
    title: '审核通过',
    text: '恭喜你入会成功',
    img: images.imageSuccess,
  },
};
