const {request} = require('../request');

// 上传图片私密图片
export function uploadSecretImage(data) {
  return request.post(`/common/uploadSecretImage`, data);
}

// 上传图片公开图片
export function uploadOpenImage(data) {
  return request.post(`/common/uploadOpenImage`, data);
}

// 上传公开文件
export function uploadOpenFile(data) {
  return request.post(`/common/uploadOpenFile`, data);
}
