import {useState} from 'react';
import {useEffect} from 'react';
import {RiArrowRightSLine} from 'react-icons/ri';

import {images} from '../../assets';
import {Main} from '../../components/Main';
import {getMsgList} from '../../helpers/requestApi/xhtMsgController';
import {
  MenuList,
  MenuItem,
  MenuItemIcon,
  MenuItemText,
  MenuItemLeft,
  RedPoint,
} from './components';
import {
  ProfileButtonGroup,
  ProfileButtonLink,
  ProfileButtonIcon,
  ProfileButtonDivider,
  CircleRadius,
} from './components';
import {ProfileCard} from './ProfileCard';

export function UserPage() {
  const [flagRead, setFlagRead] = useState(false);

  useEffect(() => {
    getMsgList({
      flagRead: false,
    }).then((res) => {
      setFlagRead(!!res.total);
    });
  }, []);

  return (
    <Main className='UserPage'>
      <ProfileCard />

      <ProfileButtonGroup>
        <ProfileButtonLink to='/activity/mine'>
          <ProfileButtonIcon src={images.user.huodong} />
          我的活动
        </ProfileButtonLink>
        <ProfileButtonDivider />
        <ProfileButtonLink to='/user/notification'>
          {flagRead && <RedPoint />}
          <ProfileButtonIcon src={images.user.xiaoxi} />
          消息
        </ProfileButtonLink>
      </ProfileButtonGroup>
      <CircleRadius />

      <MenuList>
        <MenuItem to='/user/profile'>
          <MenuItemLeft>
            <MenuItemIcon src={images.user.profile} />
            <MenuItemText>我的资料</MenuItemText>
          </MenuItemLeft>
          <RiArrowRightSLine size={18} />
        </MenuItem>

        <MenuItem to='/common-status'>
          <MenuItemLeft>
            <MenuItemIcon src={images.user.progress} />
            <MenuItemText>入会进展</MenuItemText>
          </MenuItemLeft>
          <RiArrowRightSLine size={18} />
        </MenuItem>

        <MenuItem to='/contact'>
          <MenuItemLeft>
            <MenuItemIcon src={images.user.contactUs} />
            <MenuItemText>联系我们</MenuItemText>
          </MenuItemLeft>
          <RiArrowRightSLine size={18} />
        </MenuItem>

        <MenuItem to='/user/fee/index'>
          <MenuItemLeft>
            <MenuItemIcon src={images.user.fee} />
            <MenuItemText>会员缴费</MenuItemText>
          </MenuItemLeft>
          <RiArrowRightSLine size={18} />
        </MenuItem>

        <MenuItem to='/user/profile/edit'>
          <MenuItemLeft>
            <MenuItemIcon src={images.user.userInfo} />
            <MenuItemText>会员基本信息</MenuItemText>
          </MenuItemLeft>
          <RiArrowRightSLine size={18} />
        </MenuItem>
      </MenuList>
    </Main>
  );
}
