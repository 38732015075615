import {ChakraProvider} from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';

import App from './App';
import {localStorageKeys, sessionStorageKeys} from './constants/storageKeys';
import {history} from './helpers/history';
import {toLogin} from './helpers/login';
import {getQueryVariable} from './helpers/tools';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'development') {
  const {worker} = require('./mocks/browser');
  worker.start();
}

// 重新进入系统时清空个人信息
delete sessionStorage[sessionStorageKeys.USER_INFO];

// 设置默认参数
function setDefaultParmas() {
  const siteId = getQueryVariable(localStorageKeys.SITE_ID);
  if (siteId) localStorage.setItem(localStorageKeys.SITE_ID, siteId);

  const token = getQueryVariable('third_token');
  if (token) localStorage.setItem(localStorageKeys.ACCESS_TOKEN, token);
  else if (!token && !localStorage.getItem(localStorageKeys.ACCESS_TOKEN))
    toLogin();
}
setDefaultParmas();

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
