import styled from '@emotion/styled';

import {theme} from '../constants/theme';

export const Checkbox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CheckboxText = styled.div`
  font-size: 14px;
  color: #2a2a2a;
`;

export const CheckboxSelectCSS = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #2d6dad;
  padding: 2px;
  border-radius: 2px;
  .checked {
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.PRIMARY};
    border: 1px solid ${theme.colors.PRIMARY};
    border-radius: 2px;
  }
`;

export function CheckboxSelect({checked, children}) {
  if (children) {
    return checked && children;
  }
  return (
    <CheckboxSelectCSS>
      {checked && <div className='checked' />}
    </CheckboxSelectCSS>
  );
}
