import styled from '@emotion/styled';
import {size} from 'polished';
import {NavLink} from 'react-router-dom';

import {images} from '../../assets';
import {Image} from '../../components/Image';
import {theme} from '../../constants/theme';
export const FeeCard = styled.div`
  background-color: #171717;
`;
export const MenuList = styled.div`
  padding: 0 15px;
  background-color: #fff;
`;
export const MenuItem = styled(NavLink)`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.blacks[4]};

  &:last-of-type {
    border: none;
  }

  svg {
    color: #ced7e0;
  }
`;
export const MenuItemLeft = styled.div`
  display: flex;
`;
export const MenuItemIcon = styled(Image)`
  ${size(24)}
  margin-right:6px;
`;
export const MenuItemText = styled.span`
  font-size: 15px;
`;

export const ProfileButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 17px;
  background-color: #111;
`;
export const ProfileButtonLink = styled(NavLink)`
  padding: 8px 39px;
  font-size: 13px;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
`;
export const ProfileButtonIcon = styled(Image)`
  width: 34px;
  height: 34px;
  margin-right: 8px;
  object-fit: cover;
`;
export const ProfileButtonDivider = styled.p`
  width: 0.5px;
  height: 50px;
  background-color: #ced7e0;
`;
export const CircleRadius = styled.div`
  height: 40px;
  background-color: #111;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background: url(${images.user.circleRadius}) no-repeat right top / 100% 100%;
  box-shadow: 0 20px 0 ${theme.colors.BLOCK};
`;

export const RedPoint = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff3b30;
  position: absolute;
  left: 38px;
  top: 6px;
`;
