/* eslint-disable no-empty */
import {createStandaloneToast} from '@chakra-ui/react';
import axios from 'axios';

import {localStorageKeys, getLocalStorage} from '../constants/storageKeys';
import {BASE_URL} from '../constants/url';
import {toLogin} from './login';

export const request = axios.create({
  baseURL: BASE_URL,
});

request.interceptors.request.use(
  (config) => {
    config.headers[localStorageKeys.ACCESS_TOKEN] =
      getLocalStorage('ACCESS_TOKEN');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (response) => {
    if (response.data?.code === '50' && response.data?.message) {
      const toast = createStandaloneToast();
      toast({
        description: response.data.message,
        duration: 3000,
        position: 'top',
        status: 'error',
      });
    }

    if (response.data?.code === '0') return response.data?.data;
    else if (response.data?.code === '70') {
      localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
      toLogin();
    } else {
      if (response.data?.message) {
        const toast = createStandaloneToast();
        toast({
          description: response.data.message,
          duration: 3000,
          position: 'top',
          status: 'error',
        });
      }

      return {err: true, message: response.data?.message};
    }
  },
  (error) => {
    /** @type {import('axios').AxiosResponse} */
    const {data, status} = error.response;

    switch (
      status
      // case 70:
      //   // message.warn(data.message ?? UNAUTH_MESSAGE);
      //   // localStorage.removeItem('userInfo');
      //   localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
      //   history.go('/login');
      //   break;
      // default:
      // if (data.message) message.error(data.message);
    ) {
    }

    // eslint-disable-next-line no-console
    console.error(data);

    return Promise.reject(error);
  }
);
