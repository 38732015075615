import {Button, Checkbox} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState, useEffect} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';

import {images} from '../../assets';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {getLocalStorage} from '../../constants/storageKeys';
import {userFeeSettingInfo} from '../../helpers/requestApi/xhtOrgFeeController';
import {Container} from '../Layout/components';
const PageIndex = styled.div`
  padding: 20px 15px 0 15px;
`;
const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #212121;
  line-height: 21px;
`;
const FeeInfo = styled.div`
  min-height: 113px;
  padding-left: 25px;
  padding-bottom: 20px;
  margin-top: 10px;
  background: url(${images.user.feeNumberBorder}) no-repeat left top / 100% 100%,
    url(${images.user.feeNumberBg}) no-repeat center center / 100% 100%;
`;
const LevelName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  padding: 20px 0 10px 0;
`;
const FeeNumber = styled.div`
  display: flex;
  align-items: center;
`;
const Mark = styled.div`
  color: #24579a;
  font-size: 18px;
  margin-right: 5px;
`;
const Number = styled.div`
  font-size: 30px;
  font-weight: 600;
  color: #24579a;
  margin-right: 10px;
`;
const Unit = styled.div`
  font-size: 14px;
  color: #212121;
`;
const Agreement = styled.div`
  display: flex;
  padding: 20px 0 30px;
`;
const AgreementText = styled.span`
  font-size: 14px;
  color: #a1a1a1;
`;
const BottomBtns = styled.div`
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export function UserFeeInfoPage() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [feeInfo, setFeeInfo] = useState({
    fee: '',
    levelName: '',
  });
  useEffect(() => {
    userFeeSettingInfo({
      // orgLicenseNum,
      siteId: getLocalStorage('SITE_ID'),
    }).then((res) => {
      setFeeInfo({
        fee: res.fee,
        levelName: res.levelName,
      });
    });
  }, []);
  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>会员缴费</NavTitle>
      </Navigation>
      <Main
        className='UserFeeInfoPage'
        css={css`
          background-color: #f6f6f6;
        `}
      >
        <PageIndex>
          <Title>会费选择</Title>
          <FeeInfo>
            <LevelName>{feeInfo.levelName}</LevelName>
            <FeeNumber>
              <Mark>￥</Mark>
              <Number>{feeInfo.fee}</Number>
              <Unit>/1年</Unit>
            </FeeNumber>
          </FeeInfo>
          <Agreement>
            <Checkbox
              isChecked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
            <AgreementText
              css={css`
                margin-left: 10px;
              `}
            >
              我已阅读并同意
            </AgreementText>
            <AgreementText
              css={css`
                color: #2d6dad;
                text-decoration: underline;
              `}
            >
              <NavLink to='/application-agreement'>入会协议</NavLink>
            </AgreementText>
          </Agreement>
          <BottomBtns>
            <Button
              bg='#2D6DAD'
              color='#fff'
              css={css`
                padding: 15px 35px;
              `}
              height='46px'
              isDisabled={!checked}
              lineHeight='1'
              onClick={() => {
                navigate('/user/fee/public');
              }}
            >
              公对公转账
            </Button>
            <Button
              bg='#fff'
              border='1px'
              borderColor='#2D6DAD'
              color='#2D6DAD'
              css={css`
                padding: 15px 35px;
              `}
              height='46px'
              isDisabled={!checked}
              lineHeight='1'
              onClick={() => {
                navigate('/user/fee/upload');
              }}
            >
              上传缴费凭证
            </Button>
          </BottomBtns>
        </PageIndex>
      </Main>
    </Container>
  );
}
