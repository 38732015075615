import {useCallback, useEffect, useRef, useState} from 'react';

export function useSelectFiles({
  accept = '*',
  multiple = false,
  limitMaxMB = 20,
  onFiles,
} = {}) {
  /** @type {[File[]]}]} */
  const [files, setFiles] = useState();
  const fileListRef = useRef();

  const inputRef = useRef(document.createElement('input'));
  const input = inputRef.current;

  const file = files?.[0];
  const objectUrls = files?.map((item) => URL.createObjectURL(item));
  const objectUrl = objectUrls?.[0];

  // handle unmount
  useEffect(() => {
    return () => {
      input.remove();
      objectUrls?.forEach((item) => {
        window.URL.revokeObjectURL(item);
      });
    };
  }, [input, objectUrls]);

  /** @returns {Promise<File[]>} */
  function selectFiles() {
    input.remove();
    inputRef.current = input.cloneNode(true); // clear all event listenner
    input.type = 'file';
    input.accept = accept;
    input.multiple = multiple;

    input.click();
    return new Promise((res) => {
      input.addEventListener('change', (ev) => {
        const fileList = input.files;
        const arrayFileList = Array.from(fileList);

        if (!fileList.length) return;

        // file size validation
        arrayFileList.forEach((item) => {
          if (item.size / 1024 / 1024 > limitMaxMB) {
            throw new Error(`File size exceeds ${limitMaxMB}MB limit`);
          }
        });

        setFiles(arrayFileList);
        fileListRef.current = fileList;

        if (onFiles) {
          onFiles(arrayFileList);
        }

        res(arrayFileList);
      });
    });
  }

  const reset = useCallback(() => {
    inputRef.current.remove();
    setFiles([]);
  }, []);

  return {
    fileList: fileListRef.current,
    files,
    file,
    objectUrls,
    objectUrl,
    selectFiles,
    reset,
  };
}
