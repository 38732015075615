import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {Button} from '../../components/Button';
import {Main} from '../../components/Main';
import {Navigation, NavBack, NavTitle} from '../../components/Navigation';
import {theme} from '../../constants/theme';
import {updateUserInfo} from '../../hooks/useGetUserInfo';
import {StatusImage} from './components';
import {STATUS_TEXT_MAP} from './constants';

const MainTextCSS = css`
  text-align: center;
  color: ${theme.colors.PRIMARY};
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
`;

const TextCSS = css`
  text-align: center;
  margin-top: 10px;
  color: ${theme.colors.SUBTEXT};
  font-size: 16px;
`;

const FlexBox = styled.div`
  display: flex;
  margin: 20px;
  /* gap: 20px; */
`;

export function StatusPage() {
  const navigate = useNavigate();
  const [vipStatus, setVipStatus] = useState('');

  // const {vipStatus} = useGetUserInfo()

  useEffect(() => {
    updateUserInfo((res) => {
      setVipStatus(res.vipStatus);

      console.log('res.vipStatus)', res.vipStatus);
    });
    // postOrderGet({
    //   siteId: localStorage.getItem(localStorageKeys.SITE_ID),
    // }).then((res) => {
    //   setCheckStatus(res?.checkStatus || 1);
    // });
  }, []);

  return (
    <Main className='StatusPage'>
      <Navigation>
        <NavBack />
        <NavTitle>入会申请</NavTitle>
      </Navigation>

      <StatusImage src={STATUS_TEXT_MAP[vipStatus || 1]?.img} />
      <div css={MainTextCSS}>{STATUS_TEXT_MAP[vipStatus]?.title}</div>
      <div css={TextCSS}>
        <div>{STATUS_TEXT_MAP[vipStatus]?.text}</div>
        {/* <div
          css={css`
            font-size: 13px;
          `}
        >
          （申请表当日仅限提交3次）
        </div> */}
      </div>
      <FlexBox>
        <Button
          onClick={() => {
            navigate(-1);
          }}
        >
          返回
        </Button>

        {vipStatus < 3 && (
          <Button
            style={{marginLeft: '20px'}}
            onClick={() => {
              navigate('/application');
            }}
          >
            重新提交
          </Button>
        )}
      </FlexBox>
    </Main>
  );
}
