const {request} = require('../request');

// 缴费记录
export function userFeePayRecord(data) {
  return request.post(`/xht/org/fee/pay/record`, data);
}
// 上传缴费截图
export function userFeePayUpload(data) {
  return request.post(`/xht/org/fee/pay/upload`, data);
}
// 公对公转账-详情
export function userFeeSettingInfo(data) {
  return request.post(`/xht/org/fee/setting/details`, data);
}
