// 校验手机
export const MYREG_REG = /^1[3456789]\d{9}$/;

// 校验两位小数
export const DECIMAL2_REG = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;

// 校验身份证
export const IDENTITYCARD_REG =
  /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;

// 校验银行卡号
export const BANKCARD_REG = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/;
