const {request} = require('../request');

// 保存修改会员
export function postOrgAdd(data) {
  return request.post(`/xht/org/add`, data);
}

// 会员详情
export function getVipInfo(data) {
  return request.post(`/xht/org/get`, data);
}

// 通讯录查看详情
export function getConcat(data) {
  return request.post(`/xht/org/concat/get`, data);
}

// 提交查看联系请求
export function getConcatAdd(data) {
  return request.post(`/xht/org/concat/add`, data);
}
