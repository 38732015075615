import styled from '@emotion/styled';
import {size} from 'polished';

const {Image} = require('../../components/Image');

export const StatusImage = styled(Image)`
  ${size(128, 155)};
  margin: 0 auto;
  margin-top: 75px;
`;
