import {
  Input,
  InputGroup,
  InputRightAddon,
  FormLabel,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import react, {useEffect, useState} from 'react';
import {RiArrowRightSLine, RiCloseFill} from 'react-icons/ri';

import {images} from '../../assets';
import {Image} from '../../components/Image';
import {UploadFile} from '../../components/UploadFile';
import {theme} from '../../constants/theme';

export const FormTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const FormBottom = styled.div``;

export const FormList = styled.div``;

export const FormItem = styled.div((props) => ({
  width: props.width ?? '100%',
  marginBottom: '20px',
  display: 'inline-block',
}));

export const DrawerContentCSS = css`
  width: 230px !important;
  background-image: url(${images.memberApply.bg1});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
`;

export const UploadFileBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 20px;
  div:not(:last-child) {
    margin-bottom: 10px;
  }
  .RiCloseFill {
    position: absolute;
    top: -5px;
    right: -5px;
    border: 1px solid ${theme.colors.PRIMARY};
    border-radius: 50%;
  }
`;

export const DownLoadBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  .left {
    font-size: 14px;
    color: ${theme.colors.SUBTEXT};
  }
  .right {
    margin-left: 30px;
    width: 55px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    background: linear-gradient(225deg, #ff827b 0%, #da251c 100%);
    border-radius: 5px;
  }
`;

export const FormSelect = react.forwardRef(
  ({fieldTip, value, onClick}, ref) => {
    return (
      <InputGroup onClick={onClick}>
        <Input
          isDisabled
          colorScheme='red'
          css={css`
            background-color: #fbfbfb;
            opacity: 1 !important;
            border-color: #ebebeb !important;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
          `}
          errorBorderColor='#DA251C'
          placeholder={fieldTip || '请选择'}
          value={value || ''}
        />
        <InputRightAddon
          children={
            <RiArrowRightSLine
              css={css`
                color: ${theme.colors.PRIMARY};
                font-size: 20px;
                border-color: #ebebeb !important;
              `}
            />
          }
          css={css`
            background-color: #fbfbfb;
            border-left: none;
          `}
        />
      </InputGroup>
    );
  }
);

export const FormImages = react.forwardRef(
  (
    {
      maxLimit = 1,
      ratio,
      defaultImage,
      setValue,
      value,
      height = '100px',
      hideClose = false,
    },
    ref
  ) => {
    const [list, setArr] = useState(() => {
      const arr = [];
      for (let i = 0; i < maxLimit; i++) {
        arr.push({url: ''});
      }
      return arr;
    });

    useEffect(() => {
      let arr = [];
      arr = value && [...value];
      if (arr) setArr(arr);
    }, [value]);
    // console.log('list', list);
    const toast = useToast();
    return (
      <UploadFileBox>
        {list.map((i, index) => {
          // const [imgUrl, setVal] = useState('');
          return (
            <UploadFile
              className='UploadFile'
              height={height}
              key={index}
              setValue={(val) => {
                if (val && val !== 'null') {
                  const arr = [...list];
                  arr[index].url = val;
                  setArr(arr);
                  setValue(arr);
                } else {
                  toast({
                    description: '上传失败',
                    duration: 3000,
                    position: 'top',
                    status: 'error',
                  });
                }
              }}
              width={ratio && `${100 * ratio}px`}
            >
              {list[index].url && !hideClose && (
                <RiCloseFill
                  className='RiCloseFill'
                  onClick={(e) => {
                    e.stopPropagation();
                    const arr = [...list];
                    arr[index].url = '';
                    setArr(arr);

                    const flag = !!arr.find((item) => {
                      return item.url;
                    });

                    if (flag) setValue(arr);
                    else setValue('');
                  }}
                />
              )}
              <Image
                heigth={list[index].url ? '100%' : '45'}
                src={list[index].url || defaultImage}
                width={list[index].url ? '100%' : '66'}
              />
            </UploadFile>
          );
        })}
      </UploadFileBox>
    );
  }
);

export const FormFiles = react.forwardRef(
  ({maxLimit = 1, defaultImage, setValue, value, type}, ref) => {
    const toast = useToast();
    const [list, setArr] = useState(() => {
      let arr = [];
      if (value) {
        arr = [...value];
      } else {
        for (let i = 0; i < maxLimit; i++) {
          arr.push({url: '', name: ''});
        }
      }
      return arr;
    });

    useEffect(() => {
      let arr = [];
      arr = value && [...value];
      if (arr) setArr(arr);
    }, [value]);

    return (
      <UploadFileBox>
        {list.map((i, index) => {
          return (
            // <UploadFile
            //   className='UploadFile'
            //   height='50px'
            //   key={index}
            //   setValue={(val, name) => {
            //     const arr2 = [...list];
            //     arr2[index].url = val;
            //     arr2[index].name = name;
            //     setArr(arr2);
            //     setValue(arr2);
            //   }}
            // >
            //   {list[index].url ? list[index].name : '点击上传 +'}

            // </UploadFile>

            <UploadFile
              type
              className='UploadFile'
              height='50px'
              key={index}
              setValue={(val, name) => {
                if (val && val !== 'null') {
                  const arr = [...list];
                  arr[index].url = val;
                  arr[index].name = name;
                  setArr(arr);
                  setValue(arr);
                } else {
                  toast({
                    description: '上传失败',
                    duration: 3000,
                    position: 'top',
                    status: 'error',
                  });
                }
              }}
            >
              {list[index].url && (
                <RiCloseFill
                  className='RiCloseFill'
                  onClick={(e) => {
                    e.stopPropagation();
                    const arr = [...list];
                    arr[index].url = '';
                    arr[index].name = '';

                    setArr(arr);

                    const flag = !!arr.find((item) => {
                      return item.url;
                    });

                    if (flag) setValue(arr);
                    else setValue('');
                  }}
                />
              )}
              {list[index].url ? list[index].name : '点击上传 +'}
            </UploadFile>
          );
        })}
      </UploadFileBox>
    );
  }
);

export const FormBottomItem = react.forwardRef(
  (
    {
      fieldKey,
      type,
      fieldTip,
      register,
      maxLimit,
      isInvalid,
      value,
      onClick,
      setValue,
      ratio,
    },
    ref
  ) => {
    switch (type) {
      case 'singleInput': {
        return (
          <Input
            placeholder={fieldTip || '请输入'}
            style={{
              backgroundColor: '#fbfbfb',
              height: '45px',
            }}
            type='text'
            {...register(
              fieldKey,
              isInvalid && {required: fieldTip || '请输入'}
            )}
          />
        );
      }

      case 'multiInput': {
        return (
          <Textarea
            colorScheme='red'
            css={css`
              background-color: #fbfbfb;
              opacity: 1 !important;
              border-color: #ebebeb !important;
            `}
            errorBorderColor='#DA251C'
            maxLength={maxLimit}
            placeholder={fieldTip || '请输入'}
            {...register(
              fieldKey,
              isInvalid && {required: fieldTip || '请输入'}
            )}
          />
        );
      }
      case 'radio':
      case 'checkbox': {
        return (
          <FormSelect fieldTip={fieldTip} value={value} onClick={onClick} />
        );
      }

      case 'images': {
        return (
          <FormImages
            {...register(
              fieldKey,
              isInvalid && {required: fieldTip || '请上传'}
            )}
            defaultImage={images.memberApply.file}
            maxLimit={maxLimit}
            ratio={ratio}
            setValue={setValue}
            type='image'
            value={value}
          />
        );
      }
      case 'file': {
        return (
          <FormFiles
            {...register(
              fieldKey,
              isInvalid && {required: fieldTip || '请上传'}
            )}
            maxLimit={maxLimit}
            setValue={setValue}
            type='file'
            value={value}
          />
        );
      }

      default:
        return <div />;
    }
  }
);

export const ApplicationFormLabel = styled(FormLabel)`
  font-size: 14px;
  display: flex;
  flex-flow: row-reverse;
  justify-content: flex-end;
  gap: 8px;
`;

export const ApplicationInput = styled(Input)``;
