import styled from '@emotion/styled';
import {RiErrorWarningLine} from 'react-icons/ri';

import {theme} from '../constants/theme';

export const FormTitleCSS = styled.div`
  font-size: 14px;
  color: ${theme.colors.TEXT};
  span {
    color: #da251c;
  }
`;

export const FormTipsCSS = styled.div`
  font-size: 12px;
  color: #da251c;
  height: 20px;
  width: 173px;
  padding-right: 5px;
  background: linear-gradient(
    270deg,
    rgba(218, 37, 28, 0.2) 0%,
    rgba(239, 76, 60, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .RiErrorWarningLine {
    font-size: 15px;
  }
  span {
    margin-left: 3px;
  }
`;

export const FormTextCSS = styled.div`
  font-size: 14px;
  color: ${theme.colors.SUBTEXT};
  margin: 0 0 0 3px;
`;

export function FormTitle({require = false, children}) {
  let dom = null;

  if (require) {
    dom = (
      <FormTitleCSS>
        <span>*</span>
        {children}
      </FormTitleCSS>
    );
  } else {
    dom = <FormTitleCSS>{children}</FormTitleCSS>;
  }
  return dom;
}

export function FormTips({children}) {
  return (
    <FormTipsCSS>
      <RiErrorWarningLine className='RiErrorWarningLine' />
      <span>{children}</span>
    </FormTipsCSS>
  );
}
