import wx from 'jweixin-module';
// 获取路由参数
export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

export function getTimestampBydate(date) {
  if (!date) {
    return '';
  }
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  let t = '';

  if (isiOS) {
    t = new Date(date.replace(/-/g, '/')).getTime();
  } else {
    t = new Date(date).getTime();
  }
  return t;
}

export function phoneCall(phone) {
  wx.miniProgram.navigateTo({
    url: `/pages/phoneCall/phoneCall?phone=${phone}`,
  });
}

export function addressSearch(address) {
  // wx.openLocation({
  //   longitude: 22,
  //   latitude: 114,
  //   // name: this.data.store.name,
  //   // address: this.data.store.address,
  // });

  console.log('?');

  console.log(wx.getLocation);

  wx.getLocation({
    type: 'gcj02', // 返回可以用于wx.openLocation的经纬度
    success(res) {
      const latitude = res.latitude;
      const longitude = res.longitude;
      wx.openLocation({
        latitude,
        longitude,
        scale: 18,
      });
    },
    fail(err) {
      console.log('err', err);
    },
  });
}
