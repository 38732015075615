import {Box, Text, VStack} from '@chakra-ui/react';
import styled from '@emotion/styled';

import {theme} from '../../constants/theme';
import {fontsCSS} from '../../helpers/css';

export const Container = styled.article`
  padding: 0 15px;
  margin-top: -30px;
`;

export const ProfileBlock = styled(VStack)`
  background: #ffffff;
  border-radius: 4px;
  padding: 17px 15px;
  align-items: unset;
`;

export const ProfileGroup = styled(Box)``;

export const ProfileTitle = styled.p`
  font-size: 15px;
  ${fontsCSS.semibold}
  color: #333333;
  line-height: 21px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &::before {
    content: '';
    display: block;
    width: 3px;
    height: 12px;
    background: #da271d;
    margin-right: 4px;
  }
`;

export const ProfileField = styled(Text)`
  background: ${theme.colors.blacks[4]};
  border-radius: 2px;
  font-size: 14px;
  color: #333333;
  padding: 10px 7px;
`;

export const ProfileContent = styled.p`
  font-size: 15px;
  color: #333333;
  line-height: 21px;
`;

export const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #2d6dad;
  border-radius: 2px;
  color: white;
  width: 100%;
  font-size: 15px;
`;
