import {useToast} from '@chakra-ui/react';
import {css} from '@emotion/react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {Image} from '../../components/Image';
import {Main} from '../../components/Main';
import {localStorageKeys} from '../../constants/storageKeys';
import {toLogin} from '../../helpers/login';
import {getIndexInfo} from '../../helpers/requestApi/indexController';
import {getSettingInfo} from '../../helpers/requestApi/xhtFiledSettingController';
import {useGetSiteInfo} from '../../hooks/useGetSiteInfo';
import {updateUserInfo} from '../../hooks/useGetUserInfo';
import useRepeatedlyClick from '../../hooks/useLongPress';
import {
  Banner,
  BannerItem,
  BannerList,
  BannerLogo,
  BannerTitle,
  MenuItem,
  MenuItemIcon,
  MenuItemText,
  MenuList,
} from './components';
import {menuTypes} from './constants';
import {HotActivityFeed} from './HotActivityFeed';

export function HomePage() {
  const {siteTitle} = useGetSiteInfo();

  const [siteInfo, setSiteInfo] = useState({});
  const [settingInfo, setSettingInfo] = useState({});

  const navigate = useNavigate();

  const toast = useToast();

  // const {vipStatus} = useGetUserInfo({});

  const {clickEvent} = useRepeatedlyClick(() => {
    toLogin();
  });

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    getIndexInfo().then((res) => {
      setSiteInfo(res);
    });

    getSettingInfo({
      siteId: localStorage.getItem(localStorageKeys.SITE_ID),
    }).then((res) => {
      setSettingInfo(res);
    });
  }, []);

  function handleMenuClick(item) {
    if (item.key === 'COSTPAY')
      // 入会审核成功后才可进入会费缴纳，否则走入会申请逻辑
      updateUserInfo((res) => {
        const path =
          res.vipStatus >= 3
            ? item.path
            : res.vipStatus >= 1
            ? '/common-status'
            : menuTypes.JOIN.path;

        if (res.vipStatus < 3)
          toast({
            description: '温馨提示：您暂不是会员，请先申请入会',
            duration: 3000,
            position: 'top',
            status: 'warning',
          });

        navigate(path);
      });
    else if (item.key === 'JOIN') {
      updateUserInfo((res) => {
        const path = res.vipStatus >= 1 ? '/common-status' : item.path;
        navigate(path);
      });
    } else navigate(item.path);
  }

  return (
    <Main
      className='HomePage'
      css={css`
        background: url(${settingInfo?.associationBackImg}) no-repeat right top /
          100% 365px;
        padding-bottom: 10vh;
      `}
    >
      <Banner>
        <BannerTitle>
          <BannerLogo
            size={40}
            src={siteInfo.siteLogoImg}
            onClick={clickEvent}
          />
          {siteTitle}
        </BannerTitle>

        <BannerList>
          <Slider {...settings}>
            {siteInfo.bannerList?.map((item, index) => {
              return (
                <BannerItem key={index}>
                  <Image objectFit='cover' src={item.imgUrl} />
                </BannerItem>
              );
            })}
          </Slider>
        </BannerList>
      </Banner>

      <MenuList>
        {Object.values(menuTypes).map((menu) => (
          <MenuItem
            key={menu.path}
            onClick={() => {
              if (localStorage.getItem(localStorageKeys.ACCESS_TOKEN))
                handleMenuClick(menu);
              else toLogin();
            }}
          >
            <MenuItemIcon src={menu.icon} />
            <MenuItemText>{menu.name}</MenuItemText>
          </MenuItem>
        ))}
      </MenuList>

      {/* <HotInfoFeed /> */}
      <HotActivityFeed />
    </Main>
  );
}
