/* eslint-disable no-unused-vars */
import {Flex} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {RiMapPinLine, RiTimeLine} from 'react-icons/ri';
import {useNavigate, useParams} from 'react-router-dom';

import {images} from '../../assets';
import {Button} from '../../components/Button';
import {Image} from '../../components/Image';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {localStorageKeys} from '../../constants/storageKeys';
import {getActivityDetail} from '../../helpers/requestApi/xhtActivityController';
import {getTimestampBydate} from '../../helpers/tools';
import {ItemSignUpAvatar, ItemSignUpCaption} from '../HomePage/HotActivityFeed';
import {Container} from '../Layout/components';
import {
  Activity,
  ActivityContent,
  ActivityTitle,
  Banner,
  DetailCard,
  DetailTitle,
  InfoText,
  Orangle,
  TimeAddressBox,
  TimeBox,
  TimeLeft,
  TimeRight,
} from './components';

export function ActivityDetailPage() {
  const params = useParams();

  // eslint-disable-next-line no-unused-vars
  const [details, setDetails] = useState({});

  const [timeObj, setTimeObj] = useState({
    day: '',
    hour: '',
    minutes: '',
    seconds: '',
  });

  const [timeGap, setTimeGap] = useState(0);

  // const

  function getTimeText() {
    const start = getTimestampBydate(details.applyDateStart);
    const end = getTimestampBydate(details.applyDateEnd);
    if (start > Date.now()) return '报名未开始';
    else if (end < Date.now()) return '报名已结束';
    else if (details.flagApply) return '已报名';
    else if (start) return 1; // 可报名
    return '报名未开始';
  }

  useEffect(() => {
    if (timeGap > 0) {
      const dayTime = 1000 * 3600 * 24;
      const day = parseInt(timeGap / dayTime, 10);
      const hourTime = 1000 * 3600;
      const hour = parseInt((timeGap - day * dayTime) / hourTime, 10);
      const minutesTime = 1000 * 60;
      const minutes = parseInt(
        (timeGap - day * dayTime - hour * hourTime) / minutesTime,
        10
      );
      const secondsTime = 1000;
      const seconds = parseInt(
        (timeGap - day * dayTime - hour * hourTime - minutes * minutesTime) /
          secondsTime,
        10
      );
      setTimeout(() => {
        setTimeGap(timeGap - 1000);
      }, 1000);
      setTimeObj({
        day,
        hour,
        minutes,
        seconds,
      });
    }
  }, [timeGap]);

  const navigate = useNavigate();

  useEffect(() => {
    getActivityDetail({
      siteId: localStorage.getItem(localStorageKeys.SITE_ID),
      activityId: params.activityId,
    }).then((res) => {
      setDetails(res || {});

      const time = getTimestampBydate(details.activityDateEnd) - Date.now();

      setTimeGap(time);
    });
  }, [details.activityDateEnd, params.activityId]);

  return (
    <Container>
      <Navigation>
        <NavBack />

        <NavTitle>活动详情</NavTitle>
      </Navigation>

      <Main padding='10px 15px 25px 15px'>
        <Banner>
          <Image objectFit='fill' src={details.activityImg} />
        </Banner>

        <DetailCard>
          <DetailTitle>{details.activityTitle}</DetailTitle>

          <Flex pt='10px'>
            <InfoText>发起人：{details.activityInitiator}</InfoText>
          </Flex>

          <Flex
            alignItems='center'
            className='signUp'
            justifyContent='space-between'
            pt='10px'
          >
            <Flex alignItems='center'>
              <InfoText>已报名用户：</InfoText>

              <Flex className='avatars' flexFlow='row-reverse'>
                {details.headPhotos?.map((i, k) => {
                  return (
                    k < 5 && (
                      <ItemSignUpAvatar
                        key={k}
                        src={i || images.tabbar.iconUserActive}
                      />
                    )
                  );
                })}
              </Flex>
              <ItemSignUpCaption>
                {details.headPhotos?.length || 0}人报名
              </ItemSignUpCaption>
            </Flex>
            {details.applyAuth && (
              <InfoText>限{details.applyAuth.split(',').length}人报名</InfoText>
            )}
          </Flex>

          {getTimeText() !== '活动未开始' && getTimeText() !== '活动已结束' && (
            <TimeBox alignItems='center' justifyContent='space-between'>
              <TimeLeft alignItems='center' gap='5px'>
                <Image size={15} src={images.activity.time} />
                报名中
              </TimeLeft>

              <TimeRight>
                距报名结束还有
                <Orangle>{timeObj.day}</Orangle>天
                <Orangle>{timeObj.hour}</Orangle>时
                <Orangle>{timeObj.minutes}</Orangle>分
                <Orangle>{timeObj.seconds}</Orangle>秒
              </TimeRight>
            </TimeBox>
          )}

          <TimeAddressBox>
            <Flex alignItems='center' gap='5px' mt='15px'>
              <RiTimeLine />
              <InfoText>{details.activityDateStart}</InfoText>
            </Flex>
            <Flex alignItems='center' gap='5px' mt='10px'>
              <RiMapPinLine />
              <InfoText>{details.activityAddress}</InfoText>
            </Flex>
          </TimeAddressBox>
        </DetailCard>

        <Activity>
          <ActivityTitle>活动介绍</ActivityTitle>
          <ActivityContent
            dangerouslySetInnerHTML={{__html: details.activityContent}}
          />
        </Activity>
      </Main>

      <Flex gap={10} p='13px 20px 13px 20px'>
        {/* <Button
          backgroundColor={theme.colors.BLOCK}
          border={`1px solid ${theme.colors.PRIMARY}`}
          borderRadius='2px'
          color={theme.colors.PRIMARY}
          fontSize='15px'
        >
          分享
        </Button> */}
        {details.flagApplyAuth && (
          <Button
            borderRadius='2px'
            disabled={getTimeText() !== 1}
            fontSize='15px'
            onClick={() => {
              navigate(`/activity/apply/${params.activityId}`);
            }}
          >
            {getTimeText() === 1 ? '立即报名' : getTimeText()}
          </Button>
        )}
      </Flex>
    </Container>
  );
}
