import {useEffect, useState} from 'react';

import {localStorageKeys, sessionStorageKeys} from '../constants/storageKeys';
import {getUserInfo} from '../helpers/requestApi/xhtOrgOrderController';

// write your custom hooks e.g.
export function useGetUserInfo() {
  const [userInfo, setUserInfo] = useState(
    JSON.parse(sessionStorage.getItem(sessionStorageKeys.USER_INFO))
  );
  useEffect(() => {
    if (!userInfo && localStorage.getItem(localStorageKeys.ACCESS_TOKEN))
      updateUserInfo((res) => setUserInfo(res));
  }, [userInfo]);

  return {
    userInfo,
    vipStatus: userInfo?.vipStatus, // 审核状态 -1 未提交,0草稿 1审核中 2驳回 3入会审核成功 4待缴费 5缴费成功 6正式会员
  };
}

export function updateUserInfo(getInfo) {
  getUserInfo({
    siteId: localStorage.getItem(localStorageKeys.SITE_ID),
  }).then((res) => {
    if (getInfo) getInfo(res);
    if (res)
      sessionStorage.setItem(sessionStorageKeys.USER_INFO, JSON.stringify(res));
  });
}
