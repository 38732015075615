import {images} from '../../assets';

export const menuTypes = {
  ABOUT: {
    key: 'ABOUT',
    path: 'about',
    name: '关于协会',
    icon: images.home.iconMenu1,
  },
  // COMMENT: {
  //   key: 'COMMENT',
  //   path: 'comment',
  //   name: '在线留言',
  //   icon: images.home.iconMenu2,
  // },
  // VIP: {
  //   key: 'VIP',
  //   path: 'vip',
  //   name: '会员权益',
  //   icon: images.home.iconMenu3,
  // },
  COSTPAY: {
    key: 'COSTPAY',
    path: '/user/fee/index',
    name: '会费缴纳',
    icon: images.home.iconMenu6,
  },
  JOIN: {
    key: 'JOIN',
    path: '/application',
    name: '申请入会',
    icon: images.home.iconMenu4,
  },
  CONTACT: {
    key: 'CONTACT',
    path: 'contact',
    name: '联系我们',
    icon: images.home.iconMenu5,
  },
};
