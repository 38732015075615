const {request} = require('../request');

// 消息列表
export function getMsgList(data) {
  return request.post(`/xht/msg/list`, data);
}

// 消息已读
export function postMsgRead(data) {
  return request.post(`/xht/msg/read`, data);
}
