import {Flex} from '@chakra-ui/react';
import {useState} from 'react';
import {RiSearchLine} from 'react-icons/ri';

import {Main} from '../../components/Main';
import {NavBack} from '../../components/Navigation';
import {theme} from '../../constants/theme';
import {
  SearchContainer,
  SearchInput,
  SearchIcon,
} from '../AddressBookPage/components';
import {HotActivityFeed} from '../HomePage/HotActivityFeed';
import {Container} from '../Layout/components';
import {NavBackBox} from './components';

export function ActivityPage() {
  const [input, setInput] = useState('');

  return (
    <Container
      style={{
        padding: '10px 15px',
      }}
    >
      <Flex pb='10px'>
        <NavBackBox>
          <NavBack />
        </NavBackBox>
        <SearchContainer flex={1}>
          <SearchInput
            backgroundColor={theme.colors.BLOCK}
            placeholder='搜索活动'
            style={{backgroundColor: 'white'}}
            value={input}
            onChange={(ev) => setInput(ev.target.value)}
          />
          <SearchIcon>
            <RiSearchLine size={18} />
          </SearchIcon>
        </SearchContainer>
      </Flex>

      <Main
        className='ActivityPage'
        style={{
          padding: '0',
        }}
      >
        <HotActivityFeed hideTitle px={0} searchText={input} />
      </Main>
    </Container>
  );
}
