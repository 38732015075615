// localStorage
export const localStorageKeys = {
  ACCESS_TOKEN: 'access_token',
  SITE_ID: 'siteId',
  VIP_ID: 'vipId',
};
// sessionStorage
export const sessionStorageKeys = {
  SITE_TITLE: 'siteTitle',
  USER_INFO: 'userInfo',
};
export function getLocalStorage(key) {
  return localStorage.getItem(localStorageKeys[key]);
}
export function setLocalStorage(key, value) {
  return localStorage.setItem(key, value);
}
