import {Box, Flex, Spacer} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {size} from 'polished';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {images} from '../../assets';
import {Image} from '../../components/Image';
import {localStorageKeys} from '../../constants/storageKeys';
import {theme} from '../../constants/theme';
import {
  getActivityList,
  getActivityMineList,
} from '../../helpers/requestApi/xhtActivityController';
import {getTimestampBydate} from '../../helpers/tools';
import {FeedTitleRow} from './components';

export const List = styled.div``;

export const Item = styled.div`
  background-color: white;
  box-shadow: 0px 2px 10px 0px rgba(124, 152, 180, 0.3);
  border-radius: 5px;
  margin-bottom: 20px;
`;

export const ItemCover = styled(Image)`
  ${size(90)}
  object-fit: cover;
  border-radius: 5px;
`;

export const ItemTitle = styled.p`
  font-size: 13px;
  font-family: ${theme.fonts.PINGFANG_SEMIBOLD};
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ItemCaption = styled.p`
  font-size: 12px;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const ItemSignUpAvatar = styled(Image)`
  ${size(18)}
  border-radius: 50%;
  border: 1px solid #2d6dad;
  background-color: white;

  &:not(:last-of-type) {
    margin-left: -5px;
  }
`;

export const ItemSignUpCaption = styled.span`
  font-size: 12px;
  color: #8d8d8d;
  margin-left: 4px;
`;

export const SignUpButton = styled.button(({disabled}) => ({
  background: disabled ? '#CECED1' : '#2d6dad',
  color: 'white',
  boxShadow: '0px 2px 10px 0px rgba(124, 152, 180, 0.3)',
  borderRadius: '15px',
  padding: '6px 16px',
  fontSize: '12px',
}));

export const Divider = styled.hr`
  height: 1px;
  opacity: 0.1;
  border-bottom: 0.5px solid #264c8f;
`;

export function HotActivityFeed({hideTitle, px, searchText}) {
  // const {isOpen, onOpen, onClose} = useDisclosure();
  // const navigate = useNavigate();
  const [list, setList] = useState([]);

  const navigate = useNavigate();

  const params = useParams();

  function handleApply(item, e) {
    e.stopPropagation();
    navigate(`/activity/apply/${item.activityId}`);
  }

  function getTimeText(item) {
    const start = getTimestampBydate(item.applyDateStart);
    const end = getTimestampBydate(item.applyDateEnd);
    if (start > Date.now()) return '报名未开始';
    else if (end < Date.now()) return '报名已结束';
    else if (item.flagApply) return '已报名';
    else if (start) return 1; // 可报名
    return '报名未开始';
  }

  useEffect(() => {
    const api = params.type === 'mine' ? getActivityMineList : getActivityList;

    api({
      page: 1,
      pageSize: 999,
      siteId: localStorage.getItem(localStorageKeys.SITE_ID),
    }).then((res) => {
      if (res) setList(res.list);
    });
  }, [params.type]);

  return (
    <Box className='HotActivityFeed' px={px ?? '15px'}>
      {!hideTitle && (
        <FeedTitleRow
          image={images.home.bgTitleHotActivity}
          onClick={() => {
            navigate('/activity/all');
          }}
        >
          更多
        </FeedTitleRow>
      )}
      <List>
        {list.map((item, key) => {
          return (
            (!searchText || item.activityTitle.includes(searchText)) && (
              <Item
                key={key}
                onClick={() => {
                  navigate(`/activity/detail/${item.activityId}`);
                }}
              >
                <Flex className='info' p='10px'>
                  <ItemCover src={item.activityImg} />
                  <div style={{marginLeft: '10px'}}>
                    <ItemTitle>{item.activityTitle}</ItemTitle>
                    <ItemCaption>
                      时间：
                      {item.activityDateStart
                        ? item.activityDateStart.split(' ')[0]
                        : ''}
                    </ItemCaption>
                    <ItemCaption>地点：{item.activityAddress}</ItemCaption>
                  </div>
                </Flex>
                <Divider />
                <Flex alignItems='center' className='signUp' p='10px'>
                  <Flex className='avatars' flexFlow='row-reverse'>
                    {item?.headPhotos.map((i, k) => {
                      return (
                        k < 5 && (
                          <ItemSignUpAvatar
                            key={k}
                            src={i || images.tabbar.iconUserActive}
                          />
                        )
                      );
                    })}
                  </Flex>
                  <ItemSignUpCaption>
                    {item?.headPhotos.length}人报名
                  </ItemSignUpCaption>
                  <Spacer />

                  {getTimeText(item) !== 1 && (
                    <SignUpButton disabled>{getTimeText(item)}</SignUpButton>
                  )}

                  {getTimeText(item) === 1 && item.flagApplyAuth && (
                    <SignUpButton
                      onClick={(e) => {
                        handleApply(item, e);
                      }}
                    >
                      立即报名
                    </SignUpButton>
                  )}
                </Flex>
              </Item>
            )
          );
        })}
      </List>
      {(!list || !list.length) && (
        <div style={{textAlign: 'center'}}>暂无活动内容</div>
      )}
    </Box>
  );
}
