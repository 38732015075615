import {Avatar, Center, Flex, Input} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {size} from 'polished';
import {NavLink} from 'react-router-dom';

import {theme} from '../../constants/theme';
import {fontsCSS} from '../../helpers/css';

export const SearchContainer = styled(Flex)`
  align-items: center;
  position: relative;
`;

export const SearchInput = styled(Input)`
  height: 36px;
  width: 100%;
  border-radius: 100px;
  padding-right: 30px;
  font-size: 14px;
  background-color: #f2f2f2;
  border: none;
`;

export const SearchIcon = styled(Center)`
  position: absolute;
  right: 10px;
  color: ${theme.colors.PRIMARY};
`;

export const Tabs = styled(Flex)`
  align-items: center;
  /* gap: 14px; */
  overflow-x: auto;
  padding: 10px 15px 10px 0;
  scroll-padding-left: 15px;
`;

export const TabNavItem = styled(NavLink)`
  ${fontsCSS.regular}
  color: #666666;
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;

  &.active {
    ${fontsCSS.medium}
    color: ${theme.colors.TEXT}
  }

  &.active::after {
    content: '';
    display: block;
    width: 50%;
    height: 3px;
    background: #da271d;
    border-radius: 2px;
    position: absolute;
    bottom: -5px;
  }
`;

export const MemberList = styled(Flex)`
  flex-direction: column;
  /* gap: 15px; */
`;

export const MemberItem = styled(Flex)`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  /* gap: 6px; */
`;

export const MemberItemAvatar = styled(Avatar)`
  border-radius: 15px;
  margin-right: 6px;
  ${size(45)}
`;
