import {theme} from '../constants/theme';
// write your utility css module e.g.
// export const buttonCSS = css`
//   color: royalblue;
// `;

// or write your utility css method e.g.
// export const size = (sizes) => `width: ${sizes}px; height: ${sizes}px;`;

export const fontsCSS = {
  regular: `font-family: ${theme.fonts.PINGFANG_REGULAR};
  font-weight: normal;`,
  medium: `font-family: ${theme.fonts.PINGFANG_MEDIUM};
  font-weight: bold;`,
  semibold: `font-family: ${theme.fonts.PINGFANG_SEMIBOLD};
  font-weight: bold;`,
};
