import {Box, SkeletonText} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState} from 'react';
import {RiArrowLeftCircleLine} from 'react-icons/ri';
import {Link} from 'react-router-dom';

import {Image} from '../../components/Image';
import {Main} from '../../components/Main';
import {NavBack} from '../../components/Navigation';
import {ProfileCard} from '../UserPage/ProfileCard';
import {
  Container,
  EditButton,
  ProfileBlock,
  ProfileField,
  ProfileGroup,
  ProfileTitle,
} from './components';
const company_type = {
  1: '国企',
  2: '集体企业',
  3: '私营企业',
  4: '有限责任公司',
  5: '股份有限公司',
  6: '中外合资企业',
  7: '外商投资',
  8: '其他',
};
const ImageBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const NavBackCSS = css`
  position: absolute;
  left: 3px;
  top: 3px;
  height: auto;
  color: #fff;
  margin-left: 0;
  z-index: 1;
`;
export function UserProfilePage() {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({
    orgName: '',
    orgAddress: '',
    vipPhone: '',
    vipJob: '',
    orgType: '',
    fileds: [],
  });
  function getUserInfo(data) {
    setLoading(false);
    const obj = {};
    for (const key in userInfo) {
      if (Object.hasOwnProperty.call(userInfo, key)) {
        if (key === 'fileds') {
          obj[key] = data[key] || [];
          obj[key].forEach((item) => {
            if (item.answerValue) {
              if (item.fieldType === 'checkbox')
                item.answerValue = JSON.parse(item.answerValue).join(',');

              if (item.fieldType === 'images' || item.fieldType === 'file')
                item.answerValue = JSON.parse(item.answerValue);
            }
          });
        } else {
          obj[key] = data[key];
        }
      }
    }
    setUserInfo(obj);
  }

  return (
    <Main className='UserProfilePage'>
      <NavBack css={NavBackCSS}>
        <RiArrowLeftCircleLine size={30} />
      </NavBack>

      <ProfileCard getUserInfo={getUserInfo} />
      <Box bg='#111' height='40px' />

      <Container>
        <SkeletonText isLoaded={!loading} noOfLines={10}>
          <ProfileBlock spacing={4}>
            <ProfileGroup>
              <ProfileTitle>企业名称</ProfileTitle>
              <ProfileField>{userInfo.orgName}</ProfileField>
            </ProfileGroup>
            <ProfileGroup>
              <ProfileTitle>公司地址</ProfileTitle>
              <ProfileField>{userInfo.orgAddress}</ProfileField>
            </ProfileGroup>
            <ProfileGroup>
              <ProfileTitle>手机</ProfileTitle>
              <ProfileField>{userInfo.vipPhone}</ProfileField>
            </ProfileGroup>
            {/* <ProfileGroup>
              <ProfileTitle>职称</ProfileTitle>
              <ProfileField>{userInfo.vipJob}</ProfileField>
            </ProfileGroup> */}
            <ProfileGroup>
              <ProfileTitle>单位性质</ProfileTitle>
              <ProfileField>{company_type[userInfo.orgType]}</ProfileField>
            </ProfileGroup>
            {userInfo.fileds.map(
              (item) =>
                item.answerValue && (
                  <ProfileGroup key={item.fieldId}>
                    <ProfileTitle>{item.fieldName}</ProfileTitle>
                    {item.fieldType === 'images' ? (
                      <ImageBox>
                        {item.answerValue.map((i, k) => {
                          return (
                            <Image
                              css={css`
                                margin-bottom: 2px;
                              `}
                              key={k}
                              src={i.url}
                              width='45%'
                            />
                          );
                        })}
                      </ImageBox>
                    ) : item.fieldType === 'file' ? (
                      item.answerValue.map((i, k) => {
                        return <ProfileField key={k}>{i.name}</ProfileField>;
                      })
                    ) : (
                      <ProfileField>{item.answerValue}</ProfileField>
                    )}
                  </ProfileGroup>
                )
            )}
          </ProfileBlock>
        </SkeletonText>
      </Container>

      <Box my={4} px='15px'>
        <EditButton as={Link} to='/user/profile/edit'>
          修改资料
        </EditButton>
      </Box>
    </Main>
  );
}
