import styled from '@emotion/styled';
import {RiErrorWarningLine} from 'react-icons/ri';

import {theme} from '../../constants/theme';

export const Container = styled.div`
  padding: 16px 7px 20px;
  background-color: #fff;
  border-radius: 0px 0px 4px 4px;
`;

export const InfoBox = styled.div`
  background-color: #fff;
  padding: 15px 15px 0 15px;
`;

export const InfoTitle = styled.div`
  font-size: 15px;
  color: #333333;
`;

export const EditBtn = styled.button`
  color: ${theme.colors.PRIMARY};
  font-size: 15px;
`;

export const FormList = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid ${theme.colors.BORDER};
  padding-bottom: 13px;
`;
export const FormRight = styled.div`
  flex: 5;
`;

export const FormLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 2;
`;

export const FormTipsCSS = styled.div`
  font-size: 12px;
  color: #da251c;
  height: 20px;
  width: 173px;
  padding-right: 5px;
  text-align: right;
  width: 100%;
  background: linear-gradient(
    270deg,
    rgba(218, 37, 28, 0.2) 0%,
    rgba(239, 76, 60, 0) 100%
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .RiErrorWarningLine {
    font-size: 15px;
  }
  span {
    margin-left: 3px;
  }
`;

export const StepBox = styled.div`
  padding: 8px 60px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding-bottom: 20px;
`;

export const ImageTextBox = styled.div``;
export const StepText = styled.div`
  color: #999;
  text-align: center;
`;

export function FormTips({children}) {
  return (
    <FormTipsCSS>
      <RiErrorWarningLine className='RiErrorWarningLine' />
      <span>{children}</span>
    </FormTipsCSS>
  );
}
