import {
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  useToast,
} from '@chakra-ui/react';
import {useState} from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';

import {Button} from '../../components/Button';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {getLocalStorage, localStorageKeys} from '../../constants/storageKeys';
import {
  postActivityApplyGet,
  postActivityQdAdd,
} from '../../helpers/requestApi/xhtActivityController';
import {ApplicationFormLabel} from '../ApplicationPage/components';
import {Container} from '../Layout/components';
import {
  FormList,
  InfoBox,
  InfoTitle,
  FormLeft,
  FormRight,
  FormTips,
} from './components';

export function ActivitySignInPage() {
  const {
    register,
    formState: {errors},
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      vipName: '',
      vipJob: '',
      vipPhone: '',
      orgName: '',
    },
    mode: 'all',
  });

  // const [vipLevelId, setValueId] = useState('');
  const [vipId, setVipId] = useState('');

  const toast = useToast();

  const params = useParams();

  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // const request1 = getVipInfo({
    //   siteId: getLocalStorage('SITE_ID'),
    // });

    const request1 = postActivityApplyGet({
      siteId: getLocalStorage('SITE_ID'),
      activityId: params.activityId,
      applyType: 1,
    });

    Promise.all([request1]).then(([res1]) => {
      const keyMap = ['vipName', 'vipJob', 'vipPhone', 'orgName'];
      keyMap.forEach((key) => {
        setValue(key, res1[key]);
      });
      setValue('vipLevel', res1.vipLevel);

      setVipId(res1.vipId);

      if (res1.flagVip && res1.flagApply) submit();
    });
  }, [params.activityId, setValue]);

  function submit() {
    handleSubmit(signIn)();
  }

  function signIn(__form) {
    const obj = {
      ...__form,
      siteId: localStorage.getItem(localStorageKeys.SITE_ID),
      vipId: vipId || undefined,
      // vipLevel: vipLevelId || undefined,
      activityId: params.activityId,
      applyType: 2,
    };

    postActivityQdAdd(obj).then((res) => {
      if (!res?.err) {
        setIsSuccess(true);
        toast({
          description: '签到成功',
          duration: 3000,
          position: 'top',
          status: 'success',
        });
        navigate(`/activity/detail/${params.activityId}`, {replace: true});
      }
    });
  }

  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>提交签到信息</NavTitle>
      </Navigation>
      <Main padding='10px 15px 26px'>
        <InfoBox>
          <Flex align='center' justifyContent='space-between'>
            <InfoTitle>报名人信息</InfoTitle>
            {/* <EditBtn>
              <Flex align='center' gap='5px'>
                <Image size={20} src={images.activity.edit} />
                <div>编辑</div>
              </Flex>
            </EditBtn> */}
          </Flex>

          <FormList>
            <FormControl isRequired isInvalid={errors.vipName} mb='15px'>
              <Flex align='center'>
                <FormLeft>
                  <ApplicationFormLabel
                    htmlFor='vipName'
                    style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                  >
                    姓名
                  </ApplicationFormLabel>
                </FormLeft>
                <FormRight>
                  {isSuccess ? (
                    watch('vipName')
                  ) : (
                    <Input
                      id='vipName'
                      placeholder='请输入中文姓名'
                      type='text'
                      {...register('vipName', {required: '请输入姓名'})}
                      style={{
                        backgroundColor: '#fbfbfb',
                        height: '45px',
                      }}
                    />
                  )}
                </FormRight>
              </Flex>
              {errors.vipName && (
                <FormErrorMessage style={{marginTop: '4px'}}>
                  <FormTips>{errors.vipName.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.vipJob} mb='15px'>
              <Flex align='center'>
                <FormLeft>
                  <ApplicationFormLabel
                    htmlFor='vipJob'
                    style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                  >
                    职务
                  </ApplicationFormLabel>
                </FormLeft>
                <FormRight>
                  {isSuccess ? (
                    watch('vipJob')
                  ) : (
                    <Input
                      id='vipJob'
                      placeholder='请输入职务'
                      type='text'
                      {...register('vipJob', {required: '请输入职务'})}
                      style={{
                        backgroundColor: '#fbfbfb',
                        height: '45px',
                      }}
                    />
                  )}
                </FormRight>
              </Flex>
              {errors.vipJob && (
                <FormErrorMessage style={{marginTop: '4px'}}>
                  <FormTips>{errors.vipJob.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.vipPhone} mb='15px'>
              <Flex align='center'>
                <FormLeft>
                  <ApplicationFormLabel
                    htmlFor='vipPhone'
                    style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                  >
                    联系方式
                  </ApplicationFormLabel>
                </FormLeft>
                <FormRight>
                  {isSuccess ? (
                    watch('vipPhone')
                  ) : (
                    <Input
                      id='vipPhone'
                      placeholder='请输入联系方式'
                      type='text'
                      {...register('vipPhone', {required: '请输入联系方式'})}
                      style={{
                        backgroundColor: '#fbfbfb',
                        height: '45px',
                      }}
                    />
                  )}
                </FormRight>
              </Flex>
              {errors.vipPhone && (
                <FormErrorMessage style={{marginTop: '4px'}}>
                  <FormTips>{errors.vipPhone.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={errors.orgName} mb='15px'>
              <Flex align='center'>
                <FormLeft>
                  <ApplicationFormLabel
                    htmlFor='orgName'
                    style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                  >
                    单位
                  </ApplicationFormLabel>
                </FormLeft>
                <FormRight>
                  {isSuccess ? (
                    watch('orgName')
                  ) : (
                    <Input
                      id='orgName'
                      placeholder='请输入单位'
                      type='text'
                      {...register('orgName', {required: '请输入单位'})}
                      style={{
                        backgroundColor: '#fbfbfb',
                        height: '45px',
                      }}
                    />
                  )}
                </FormRight>
              </Flex>
              {errors.orgName && (
                <FormErrorMessage style={{marginTop: '4px'}}>
                  <FormTips>{errors.orgName.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormControl>

            {watch('vipLevel') && (
              <FormControl mb='15px'>
                <Flex align='center'>
                  <FormLeft>
                    <ApplicationFormLabel
                      htmlFor='vipLevel'
                      style={{margin: 0, gap: '2px', fontWeight: 'normal'}}
                    >
                      会员等级
                    </ApplicationFormLabel>
                  </FormLeft>
                  <FormRight>{watch('vipLevel')}</FormRight>
                </Flex>
              </FormControl>
            )}
          </FormList>
        </InfoBox>

        {isSuccess ? (
          <Button
            margin='26px 0 0 0'
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        ) : (
          <Button margin='26px 0 0 0' onClick={handleSubmit(signIn)}>
            提交签到
          </Button>
        )}
      </Main>
    </Container>
  );
}
