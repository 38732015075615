import {localStorageKeys} from '../../constants/storageKeys';

const {request} = require('../request');

// 获取首页log 广告位 banner
export function getIndexInfo() {
  return request.get(
    `/api/index/info?siteId=${localStorage.getItem(localStorageKeys.SITE_ID)}`
  );
}
