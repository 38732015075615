import {useClipboard, SkeletonText} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState, useEffect} from 'react';

import {images} from '../../assets';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {getLocalStorage} from '../../constants/storageKeys';
import {userFeeSettingInfo} from '../../helpers/requestApi/xhtOrgFeeController';
import {Container} from '../Layout/components';
// const MsgTip = styled.div`
//   width: 248px;
//   height: 28px;
//   line-height: 28px;
//   text-align: center;
//   background: rgba(218, 37, 28, 0.2);
//   border-radius: 14px;
//   margin: 5px auto 10px auto;
//   color: #da251c;
// `;
const FeeCard = styled.div`
  margin: 0 15px;
  text-align: center;
  height: 121px;
  background: url(${images.user.accountBg}) no-repeat top left / 100% 100%;
`;
const Text = styled.div`
  font-size: 14px;
  color: #212121;
  padding-top: 22px;
`;
const Number = styled.div`
  padding-top: 15px;
  font-size: 30px;
  color: #24579a;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StepList = styled.div`
  padding: 15px;
  border-bottom-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
      270deg,
      rgba(73, 153, 243, 0.2),
      rgba(35, 96, 228, 0.2)
    )
    1 1;
`;
const StepListTitle = styled.div`
  text-align: center;
  color: #212121;
  font-size: 15px;
  font-weight: 600;
`;
const StepListItem = styled.div`
  padding: 11px 0 0px 0;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 10px;
    bottom: -11px;
    width: 1px;
    height: calc(100% - 20px);
    background: #e4ecf4;
  }
  &:last-child {
    &::before {
      width: 0px;
    }
  }
`;
const ItemTop = styled.div`
  display: flex;
`;
const StepListIcon = styled.div(
  (props) => css`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url(${props.image}) no-repeat left top / 100% 100%;
  `
);
const StepSpan = styled.div`
  color: #212121;
  font-size: 13px;
  font-weight: 600;
`;
const AccountInfo = styled.div`
  padding: 10px 11px 10px 20px;
`;
const AccountTitle = styled.div`
  color: #212121;
  padding: 10px;
  font-size: 13px;
  background: url(${images.user.accountInfoCard}) no-repeat left top / 100% 100%;
`;
const AccountText = styled.div`
  color: #505050;
  padding: 10px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
`;
const TipList = styled.div`
  padding: 15px 25px;
`;
const Title = styled.div`
  font-size: 15px;
  color: #212121;
  text-align: center;
  padding-bottom: 15px;
`;
const ListItem = styled.div`
  color: #a2a2a2;
  padding-left: 13px;
  padding-bottom: 23px;
  position: relative;
  font-size: 13px;
  line-height: 18px;
  &::before {
    position: absolute;
    content: '';
    background-color: #2d6dad;
    width: 8px;
    height: 8px;
    left: 0;
    top: 6px;
    border-radius: 50%;
    margin-right: 5px;
  }
`;
export function UserFeePublicPage() {
  const [loading, setLoading] = useState(true);
  const [accountInfo, setAccountInfo] = useState({
    accountName: '',
    accountNumber: '',
    bankAddress: '',
    bankName: '',
    fee: '',
  });

  const [copyKey, setCopyKey] = useState('accountName');
  const accountNameCopy = useClipboard(accountInfo.accountName);
  const accountNumberCopy = useClipboard(accountInfo.accountNumber);
  function changeCopyValue(key) {
    setCopyKey(key);
    if (key === 'accountName') {
      accountNameCopy.onCopy();
    } else {
      accountNumberCopy.onCopy();
    }
  }
  useEffect(() => {
    // setTimeout(() => {
    setLoading(false);
    // }, 5000);
    userFeeSettingInfo({
      // orgLicenseNum,
      siteId: getLocalStorage('SITE_ID'),
    }).then((res) => {
      setAccountInfo(res);
    });
  }, []);
  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>公对公转账</NavTitle>
      </Navigation>

      <Main
        className='UserFeePublicPage'
        css={css`
          padding: 5px 0px;
          background-color: #fff;
        `}
      >
        {/* <MsgTip>请在1天23小时59分内完成转账</MsgTip> */}
        <FeeCard>
          <Text>转账金额</Text>
          <Number>
            <span
              css={css`
                font-size: 18px;
              `}
            >
              ￥
            </span>
            {accountInfo.fee}
          </Number>
        </FeeCard>
        {/* 操作步骤 */}
        <StepList>
          <StepListTitle>操作步骤</StepListTitle>
          <StepListItem
            css={css`
              padding-bottom: 24px;
            `}
          >
            <ItemTop>
              <StepListIcon image={images.user.step1} />
              <StepSpan>打开手机银行App/PC网银，或前往银行柜台</StepSpan>
            </ItemTop>
          </StepListItem>
          <StepListItem>
            <ItemTop>
              <StepListIcon image={images.user.step2} />
              <StepSpan>用您的银行卡向以下账户进行转账</StepSpan>
            </ItemTop>
            <AccountInfo>
              <SkeletonText isLoaded={!loading} noOfLines={5}>
                <AccountTitle>收款账户信息</AccountTitle>
                <AccountText>
                  <span>
                    收款户名
                    <span
                      css={css`
                        color: #a2a2a2;
                        margin-left: 10px;
                      `}
                    >
                      {accountInfo.accountName}
                    </span>
                  </span>
                  <span
                    css={css`
                      color: #24579a;
                    `}
                    onClick={() => {
                      changeCopyValue('accountName');
                    }}
                  >
                    {accountNameCopy.hasCopied && copyKey === 'accountName'
                      ? '已复制'
                      : '复制'}
                  </span>
                </AccountText>
                <AccountText>
                  <span>
                    收款账号
                    <span
                      css={css`
                        color: #a2a2a2;
                        margin-left: 10px;
                      `}
                    >
                      {accountInfo.accountNumber}
                    </span>
                  </span>
                  <span
                    css={css`
                      color: #24579a;
                    `}
                    onClick={() => {
                      changeCopyValue('accountNumber');
                    }}
                  >
                    {accountNumberCopy.hasCopied && copyKey === 'accountNumber'
                      ? '已复制'
                      : '复制'}
                  </span>
                </AccountText>
                <AccountText>
                  <span>
                    收款银行
                    <span
                      css={css`
                        color: #a2a2a2;
                        margin-left: 10px;
                      `}
                    >
                      {accountInfo.bankName}
                    </span>
                  </span>
                </AccountText>
                <AccountText>
                  <span>
                    所在地&nbsp;&nbsp;&nbsp;
                    <span
                      css={css`
                        color: #a2a2a2;
                        margin-left: 10px;
                      `}
                    >
                      {accountInfo.bankAddress}
                    </span>
                  </span>
                </AccountText>
              </SkeletonText>
            </AccountInfo>
          </StepListItem>
          <StepListItem>
            <ItemTop>
              <StepListIcon image={images.user.step3} />
              <StepSpan>转账之后保留凭证，到小程序里上传转账凭证</StepSpan>
            </ItemTop>
          </StepListItem>
        </StepList>
        {/* 注意事项 */}

        <TipList>
          <Title>注意事项</Title>
          <ListItem>转账金额与订单金额务必保持一致，不得多转，不得少</ListItem>
          <ListItem>
            转账的到账时间：建议转账半小时后查看到账情况。
            工作时间转账预计2小时内到账，非工作时间转账，以 付款方银行规则为准。
          </ListItem>
          <ListItem>在转账页面若找不到收款银行，可联系协会人员。</ListItem>
          <ListItem>
            转账成功后请截图上传到缴费页面，提交给工作人员审核。
          </ListItem>
          {/* <UnorderedList
          css={css`
            padding: 15px 0;
            color: #24579a;
            font-size: 13px;
          `}
          spacing='23px'
          variants='primary'
        >
          <ListItem css={css`
            padding: 15px 0;
            color: #A2A2A2;
            font-size: 13px;
          `}>
            转账金额与订单金额务必保持一致，不得多转，不得少 转，不得分次转账
          </ListItem>
          <ListItem>
            转账的到账时间：建议转账半小时后查看到账情况。
            工作时间转账预计2小时内到账，非工作时间转账，以 付款方银行规则为准。
          </ListItem>
          <ListItem>在转账页面若找不到收款银行，可联系协会人员。</ListItem>
          <ListItem>
            转账成功后请截图上传到缴费页面，提交给工作人员 审核。
          </ListItem>
        </UnorderedList> */}
        </TipList>
      </Main>
    </Container>
  );
}
