/* eslint-disable no-unused-vars */
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {css} from '@emotion/react';
import {useState, useRef, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {RiCheckFill} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

import {Button} from '../../components/Button';
import {
  Checkbox,
  CheckboxSelect,
  CheckboxText,
} from '../../components/Checkbox';
import {FormTips} from '../../components/FormItem';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {getLocalStorage} from '../../constants/storageKeys';
import {theme} from '../../constants/theme';
import {getFieldList} from '../../helpers/requestApi/xhtFiledSettingController';
import {
  getVipInfo,
  postOrgAdd,
} from '../../helpers/requestApi/xhtOrgVipController';
import {
  FormList,
  DrawerContentCSS,
  ApplicationFormLabel,
  FormTop,
  FormBottom,
  FormBottomItem,
} from '../ApplicationPage/components';
import {Container} from '../Layout/components';

const DrawerHeaderCSS = css`
  color: ${theme.colors.TEXT};
  font-size: 16px;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 2px;
    height: 20px;
    left: 0;
    top: 0;
    background-color: ${theme.colors.PRIMARY};
    left: -5px;
    top: 2px;
  }
`;
const company_type = [
  {itemName: '国企', rid: 1},
  {itemName: '集体企业', rid: 2},
  {itemName: '私营企业', rid: 3},
  {itemName: '有限责任公司', rid: 4},
  {itemName: '股份有限公司', rid: 5},
  {itemName: '中外合资企业', rid: 6},
  {itemName: '外商投资', rid: 7},
  {itemName: '其他', rid: 8},
];

const ApplicationPageCSS = css`
  background-color: ${theme.colors.BLOCK};
  padding-bottom: 20px;
  .css-eleoge-FormBottom {
    position: relative;
    .placeholder {
      position: absolute;
      left: var(--chakra-space-4);
      z-index: 1000;
      background-color: rgb(251, 251, 251);
      top: 50%;
      transform: translateY(-50%);
      height: 40px;
      color: #adb6c6;
      line-height: 40px;
    }
  }

  /* #establishedDate {
    input[type="] {
      &:before {
        content: attr('123');
      }
    }
  } */
`;

export function UserProfileEditPage() {
  const [form, setForm] = useState({});

  const {
    register,
    handleSubmit,
    formState: {errors, isValid},
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      orgName: '',
      legalPerson: '',
      establishedDate: '',
      orgAddress: '',
      orgWebUrl: '',
      orgType: '',
    },
    mode: 'all',
  });

  const {isOpen, onOpen, onClose} = useDisclosure();
  const btnRef = useRef();

  const vipIdRef = useRef('');

  // setCheckboxList
  // const [editingObj, setEditingObj] = useState({items: []});

  const [curKey, setCurKey] = useState('');
  const [curItems, setCurItems] = useState([]);
  const [curFieldType, setCurFieldType] = useState('radio');
  const [curSelectTitle, setCurSelectTitle] = useState('');
  const [finalChecked, setFinalChecked] = useState(false);
  const toast = useToast();

  const navigate = useNavigate();

  function handleClickInput(key, items, fieldType, selectTitle) {
    if (Array.isArray(items)) setCurItems(items);
    else setCurItems([]);

    setCurKey(key);
    setCurFieldType(fieldType || 'radio');

    setCurSelectTitle(selectTitle);

    onOpen();
  }

  function onClickCheck(item) {
    const i = curItems.find((j) => {
      return j.rid === item.rid;
    });
    if (curFieldType === 'radio') {
      curItems.forEach((j) => {
        j.checked = false;
      });

      i.checked = true;
    } else if (curFieldType === 'checkbox') {
      i.checked = !i.checked;
    }
    setCurItems([...curItems]);
  }

  function handleConfirmSelect() {
    const obj = {...form};

    const arr = curItems
      .filter((item) => {
        return item.checked;
      })
      .map((item) => {
        return item.itemName;
      });

    // obj[curKey].answerValue = arr;

    setValue(curKey, arr);

    setForm(obj);

    onClose();
    // checkForm();
  }

  function onSubmit(_form) {
    postOrgAdd({
      ...getParams(_form),
      siteId: getLocalStorage('SITE_ID'),
      vipId: vipIdRef.current,
    }).then((r) => {
      toast({
        description: '提交成功',
        duration: 3000,
        position: 'top',
        status: 'success',
      });
    });
  }

  function getParams(_form) {
    const params = {answers: []};

    const privateForm = {...form};

    Object.keys(_form).forEach((key) => {
      if (key === 'orgType') {
        const obj = company_type.find((item) => {
          return item.itemName === _form.orgType[0];
        });
        if (obj) params.orgType = obj.rid;
      } else if (privateForm[key]) {
        // 自定义字段
        if (Array.isArray(_form[key])) {
          if (privateForm[key].fieldType === 'radio')
            privateForm[key].answerValue = _form[key][0];
          else privateForm[key].answerValue = JSON.stringify(_form[key]);
        } else privateForm[key].answerValue = _form[key];

        params.answers.push(privateForm[key]);
      } else if (Array.isArray(_form[key]))
        params[key] = _form[key][0].url || '';
      else params[key] = _form[key] || '';
    });

    return params;
  }

  useEffect(() => {
    const request1 = getFieldList({
      settingType: 1,
      siteId: getLocalStorage('SITE_ID'),
    });

    const request2 = getVipInfo({
      siteId: getLocalStorage('SITE_ID'),
    });

    Promise.all([request1, request2]).then((res) => {
      setForm((prevForm) => {
        const obj = {...prevForm};
        if (Array.isArray(res[0])) {
          res[0].forEach((item) => {
            // item.isInvalid = false;
            item.css = css`
              display: block;
            `;
            if (!item.answerValue) item.answerValue = '';

            item.key = `${item.fieldId}key`;

            item.custom = true;

            obj[`${item.fieldId}key`] = item;
          });
        }
        return obj;
      });

      if (res[1]) {
        vipIdRef.current = res[1].vipId;
        const keyMap = [
          'orgName',
          'legalPerson',
          'establishedDate',
          'orgAddress',
          'orgWebUrl',
          'orgType',
        ];
        keyMap.forEach((key) => {
          setValue(key, res[1][key]);
        });

        setValue('orgLicenseImage', [{url: res[1].orgLicenseImage}]);
        setValue('vipMemberImage', [{url: res[1].vipMemberImage}]);
        const obj = company_type.find((item) => {
          return item.rid === res[1].orgType;
        });
        if (obj) setValue('orgType', [obj.itemName]);

        res[1].answers?.forEach((item) => {
          const fieldObj = res[1].fileds?.find((i) => {
            return i.fieldId === item.fieldId;
          });
          const fieldType = fieldObj?.fieldType;
          if (fieldType === 'singleInput' || fieldType === 'multiInput') {
            setValue(`${item.fieldId}key`, item.answerValue);
          } else if (fieldType === 'radio') {
            setValue(
              `${item.fieldId}key`,
              item.answerValue ? [item.answerValue] : ''
            );
          } else if (fieldType) {
            setValue(
              `${item.fieldId}key`,
              item.answerValue ? JSON.parse(item.answerValue) : ''
            );
          }
        });
      }
    });
  }, [setValue]);

  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>会员基本信息表</NavTitle>
      </Navigation>
      <Main className='ApplicationPage' css={ApplicationPageCSS}>
        <FormList style={{padding: '10px 15px 30px'}}>
          {/* orgName: '',
      legalPerson: '',
      establishedDate: '',
      orgAddress: '',
      orgWebUrl: '',
      orgType: '', */}

          <FormControl isRequired isInvalid={errors.orgName}>
            <FormTop>
              <ApplicationFormLabel
                htmlFor='orgName'
                style={{margin: 0, gap: '2px'}}
              >
                企业名称
              </ApplicationFormLabel>
              {errors.orgName && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.orgName.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='orgName'
                placeholder='请输入中文企业名称'
                type='text'
                {...register('orgName', {required: '请输入企业名称'})}
                disabled
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl isRequired isInvalid={errors.legalPerson}>
            <FormTop>
              <ApplicationFormLabel
                htmlFor='legalPerson'
                style={{marginTop: '20px', gap: '2px'}}
              >
                法人代表
              </ApplicationFormLabel>
              {errors.legalPerson && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.legalPerson.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='legalPerson'
                placeholder='请输入中文法人代表'
                type='text'
                {...register('legalPerson', {required: '请输入法人代表'})}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl isRequired isInvalid={errors.establishedDate}>
            <FormTop>
              <ApplicationFormLabel
                htmlFor='establishedDate'
                style={{marginTop: '20px', gap: '2px'}}
              >
                企业成立时间
              </ApplicationFormLabel>
              {errors.establishedDate && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.establishedDate.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              {!watch('establishedDate') && (
                <div className='placeholder'>请选择企业成立时间</div>
              )}
              <Input
                id='establishedDate'
                placeholder='请选择企业成立时间'
                type='date'
                {...register('establishedDate', {
                  required: '请选择企业成立时间',
                })}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl isRequired isInvalid={errors.orgAddress}>
            <FormTop>
              <ApplicationFormLabel
                htmlFor='orgAddress'
                style={{marginTop: '20px', gap: '2px'}}
              >
                企业地址
              </ApplicationFormLabel>
              {errors.orgAddress && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.orgAddress.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='orgAddress'
                placeholder='请输入中文企业地址'
                type='text'
                {...register('orgAddress', {required: '请输入企业地址'})}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl isRequired isInvalid={errors.orgWebUrl}>
            <FormTop>
              <ApplicationFormLabel
                htmlFor='orgWebUrl'
                style={{marginTop: '20px', gap: '2px'}}
              >
                企业网址
              </ApplicationFormLabel>
              {errors.orgWebUrl && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.orgWebUrl.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='orgWebUrl'
                placeholder='请输入中文企业网址'
                type='text'
                {...register('orgWebUrl', {required: '请输入企业网址'})}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>

          <FormControl isRequired isInvalid={errors.orgWebUrl}>
            <FormTop>
              <ApplicationFormLabel
                htmlFor='orgWebUrl'
                style={{marginTop: '20px', gap: '2px'}}
              >
                企业性质
              </ApplicationFormLabel>
              {errors.orgWebUrl && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.orgWebUrl.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <FormBottomItem
                isInvalid
                fieldKey='orgType'
                fieldTip='请选择企业性质'
                register={register}
                setValue={(val) => setValue('orgType', val)}
                type='radio'
                value={watch('orgType')}
                onClick={() => {
                  handleClickInput(
                    'orgType',
                    company_type,
                    'radio',
                    '企业性质'
                  );
                }}
              />
            </FormBottom>
          </FormControl>

          {/* 自定义字段 */}
          {Object.keys(form).map((key) => {
            return (
              <FormControl
                isInvalid={errors[key]}
                isRequired={form[key].flagRequired}
                key={key}
                style={{marginTop: '20px'}}
              >
                <FormTop>
                  <ApplicationFormLabel htmlFor={key} style={{gap: '2px'}}>
                    {form[key].fieldName}
                  </ApplicationFormLabel>
                  {errors[key] && (
                    <FormErrorMessage style={{margin: 0}}>
                      <FormTips>{errors[key].message}</FormTips>
                    </FormErrorMessage>
                  )}
                </FormTop>

                <FormBottom>
                  <FormBottomItem
                    fieldKey={key}
                    fieldTip={form[key].fieldTip}
                    isInvalid={form[key].flagRequired}
                    maxLimit={form[key].maxLimit}
                    ratio={form[key].ratio}
                    register={register}
                    setValue={(val) => setValue(key, val)}
                    type={form[key].fieldType}
                    value={watch(key)}
                    onClick={() => {
                      handleClickInput(
                        key,
                        form[key].items,
                        form[key].fieldType,
                        form[key].fieldName
                      );
                    }}
                  />
                </FormBottom>
              </FormControl>
            );
          })}
        </FormList>

        <Box px='15px'>
          <Button onClick={handleSubmit(onSubmit)}>提交</Button>
        </Box>

        <Drawer
          finalFocusRef={btnRef}
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent css={DrawerContentCSS}>
            {/* <DrawerCloseButton /> */}
            <DrawerHeader
              css={css`
                padding: 19px 0 0 15px;
              `}
            >
              <div css={DrawerHeaderCSS}>{curSelectTitle}</div>
            </DrawerHeader>

            <DrawerBody
              css={css`
                padding: 2px 20px 0 30px;
              `}
            >
              {curItems.map((item, index) => (
                <Checkbox
                  css={css`
                    margin-top: 20px;
                  `}
                  key={index}
                  onClick={() => {
                    onClickCheck(item);
                  }}
                >
                  <CheckboxText>{item.itemName}</CheckboxText>
                  <CheckboxSelect checked={item.checked || false}>
                    {curFieldType === 'radio' && (
                      <RiCheckFill
                        css={css`
                          color: ${theme.colors.PRIMARY};
                          font-size: 16px;
                        `}
                      />
                    )}
                  </CheckboxSelect>
                </Checkbox>
              ))}
            </DrawerBody>

            <DrawerFooter
              css={css`
                padding: 0;
              `}
            >
              <Button borderRadius={0} onClick={handleConfirmSelect}>
                确定
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Main>
    </Container>
  );
}
