import {css} from '@emotion/react';

import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {Container} from '../Layout/components';

const ContentCSS = css`
  padding: 10px 15px;
  .marginT {
    margin-top: 10px;
  }
`;

export function ApplicationAgreementPage() {
  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>入会协议</NavTitle>
      </Navigation>

      <Main css={ContentCSS}>
        <p>一、入会资格</p>
        <p>
          1、深圳市企业评价协会实行团体会员制，以一个法人单位作为一个团体会员。
        </p>
        <p>2、申请加入深圳市企业评价协会会员单位，必须具备下列条件：</p>
        <p>
          拥护协会的章程，有加入协会的良好意愿，对协会的评价业务有较深的认识。另外从事企业评价、评估、评级和有关管理工作的职能部门、单位和专业性团体也可申请加入。
        </p>
        <p>3、在本行业领域内具有一定影响力。</p>
        <p>4、应持有工商营业执照等相关证件。</p>
        <p className='marginT'>二、会员的权利及服务</p>
        <p>1、出席会员大会（或会员代表大会），参加协会举办的各种活动。</p>
        <p className='marginT'>三、享有协会的选举权、被选举权和表决权。</p>
        <p>2、获得协会提供各项服务的优先权。</p>
        <p>3、对协会工作的提议案权、建议权和监督权。</p>
        <p>4、有被委派或推选为会员代表的权利。</p>
        <p>5、入会自愿，退会自由。</p>
        <p>6、会员走访交流活动。</p>
        <p>
          7、信息平台：投资丝路（是一站式智能选址平台，为上万位企业主提供政策推送及选址支持）。
        </p>
        <p className='marginT'>四、会员的义务</p>
        <p>1、遵守协会的章程，执行协会的决议。</p>
        <p>2、维护协会的合法权益，支持协会的各项活动。</p>
        <p>3、完成协会交办的工作。</p>
        <p>4、按规定交纳会费。</p>
        <p>5、向协会反映情况，提供有关的信息、资料。</p>
        <p>6、其他依照章程应尽的义务。</p>
      </Main>
    </Container>
  );
}
