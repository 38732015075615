import {useToast} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

// import styled from '@emotion/styled';
import {images} from '../../assets';
import {Button} from '../../components/Button';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {localStorageKeys} from '../../constants/storageKeys';
import {userFeePayUpload} from '../../helpers/requestApi/xhtOrgFeeController';
import {FormImages} from '../ApplicationPage/components';
import {Container} from '../Layout/components';

export function UserFeeUpload() {
  const [imgUrl, setImgUrl] = useState('');
  const navigate = useNavigate();

  const TextCSS = css`
    text-align: center;
    position: absolute;
    top: 10px;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
  `;

  const SubmitBox = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #e2e8f0;
  `;

  const toast = useToast();

  function submit() {
    if (imgUrl.length) {
      userFeePayUpload({
        siteId: localStorage.getItem(localStorageKeys.SITE_ID),
        imgCetificate: imgUrl[0].url,
      }).then((res) => {
        toast({
          title: '上传成功',
          description: '审核中，请耐心等待',
          duration: 3000,
          position: 'top',
          status: 'success',
        });

        navigate(-1);
      });
    } else {
      toast({
        description: '请先上传图片',
        duration: 3000,
        position: 'top',
        status: 'warn',
      });
    }
  }

  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>缴费截图上传</NavTitle>
      </Navigation>
      <Main
        css={css`
          position: relative;
        `}
      >
        <FormImages
          hideClose
          defaultImage={images.memberApply.file}
          height='160px'
          setValue={(val) => setImgUrl(val)}
          value={imgUrl}
        />
        {!imgUrl && <div css={TextCSS}>点击上传</div>}

        <SubmitBox>
          <div>上传图片（{imgUrl ? 1 : 0}/1）</div>
          <Button width='120px' onClick={submit}>
            确定
          </Button>
        </SubmitBox>
      </Main>
    </Container>
  );
}
