/* eslint-disable no-unused-vars */
import {
  Box,
  Spinner,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {css} from '@emotion/react';
import {useState, useRef, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {RiCheckFill} from 'react-icons/ri';
import {NavLink, useNavigate} from 'react-router-dom';

import {images} from '../../assets';
import {Button} from '../../components/Button';
import {
  Checkbox,
  CheckboxSelect,
  CheckboxText,
} from '../../components/Checkbox';
import {FormTextCSS, FormTips} from '../../components/FormItem';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {getLocalStorage} from '../../constants/storageKeys';
import {theme} from '../../constants/theme';
import {IDENTITYCARD_REG, MYREG_REG} from '../../helpers/regular';
import {uploadOpenFile} from '../../helpers/requestApi/commonController';
import {getSiteTitle} from '../../helpers/requestApi/userController';
import {
  exportValidate,
  getApplyExport,
} from '../../helpers/requestApi/xhtApiController';
import {
  getFieldList,
  getSettingInfo,
} from '../../helpers/requestApi/xhtFiledSettingController';
import {
  postOrderAdd,
  postOrderGet,
} from '../../helpers/requestApi/xhtOrgOrderController';
import {useGetUserInfo} from '../../hooks/useGetUserInfo';
import {useSelectFiles} from '../../hooks/useSelectFiles';
import {Container} from '../Layout/components';
import {
  FormList,
  DrawerContentCSS,
  ApplicationFormLabel,
  FormTop,
  FormImages,
  FormSelect,
  FormBottom,
  FormBottomItem,
  DownLoadBox,
} from './components';
import {navigateToDownLoadFile} from './helper';

const DrawerHeaderCSS = css`
  color: ${theme.colors.TEXT};
  font-size: 16px;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 2px;
    height: 20px;
    left: 0;
    top: 0;
    background-color: ${theme.colors.PRIMARY};
    left: -5px;
    top: 2px;
  }
`;

export function ApplicationPage() {
  const [form, setForm] = useState({});
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
    setValue,
    // reset,
  } = useForm({
    defaultValues: {
      orgName: '',
      orgLicenseNum: '',
      orgLicenseImage: '',
      vipMemberImage: '',
      vipName: '',
      vipJob: '',
      vipIdcard: '',
      vipPhone: '',
      vipWechat: '',
    },
    mode: 'all',
  });

  const {isOpen, onOpen, onClose} = useDisclosure();
  const btnRef = useRef();

  // setCheckboxList
  // const [editingObj, setEditingObj] = useState({items: []});

  const [siteTitle, setSiteTitle] = useState('');

  const [vipLevelItems, setVipLevelItems] = useState([]);

  const [checkStatus, setCheckStatus] = useState(0);

  const [curKey, setCurKey] = useState('');
  const [curItems, setCurItems] = useState([]);
  const [curFieldType, setCurFieldType] = useState('radio');
  const [curSelectTitle, setCurSelectTitle] = useState('');
  const [finalChecked, setFinalChecked] = useState(false);
  const {userInfo} = useGetUserInfo();
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const {selectFiles} = useSelectFiles();

  const navigate = useNavigate();

  function handleClickInput(key, items, fieldType, selectTitle) {
    if (Array.isArray(items)) setCurItems(items);
    else setCurItems([]);

    setCurKey(key);
    setCurFieldType(fieldType || 'radio');

    setCurSelectTitle(selectTitle);

    onOpen();
  }

  function onClickCheck(item) {
    const i = curItems.find((j) => {
      return j.rid === item.rid;
    });
    if (curFieldType === 'radio') {
      curItems.forEach((j) => {
        j.checked = false;
      });

      i.checked = true;
    } else if (curFieldType === 'checkbox') {
      i.checked = !i.checked;
    }
    setCurItems([...curItems]);
  }

  function handleConfirmSelect() {
    const obj = {...form};

    const arr = curItems
      .filter((item) => {
        return item.checked;
      })
      .map((item) => {
        return item.itemName;
      });

    // obj[curKey].answerValue = arr;

    setValue(curKey, arr);

    setForm(obj);

    onClose();
    // checkForm();
  }

  function onSubmit(_form) {
    if (!finalChecked) {
      toast({
        description: '请勾选我已阅读并同意入会协议',
        duration: 3000,
        position: 'top',
      });
      return;
    }
    selectFiles().then(([file]) => {
      const formData = new FormData();
      formData.set('files', file);
      setLoading(true);
      uploadOpenFile(formData).then((res) => {
        setLoading(false);
        if (!res.err) {
          postOrderAdd({
            ...getParams(_form),
            fileSealImage: res,
            checkStatus: 1,
            siteId: getLocalStorage('SITE_ID'),
          }).then((r) => {
            navigate('/common-status');
          });
        }
      });
    });
  }

  function downLoad(_form) {
    const params = getParams(_form);
    postOrderAdd({
      ...params,
      siteId: getLocalStorage('SITE_ID'),
      checkStatus: checkStatus || 0,
    });

    const obj = vipLevelItems.find((item) => {
      return item.rid === params.vipLevel;
    });

    exportValidate({
      ...params,
      siteId: getLocalStorage('SITE_ID'),
      siteName: siteTitle,
      vipLevelName: obj?.itemName,
    }).then((res) => {
      navigateToDownLoadFile(getApplyExport(res));
    });
  }

  function getParams(_form) {
    const params = {answers: []};

    const privateForm = {...form};

    Object.keys(_form).forEach((key) => {
      if (key === 'vipLevel') {
        const obj = vipLevelItems.find((item) => {
          return item.itemName === _form.vipLevel[0];
        });
        if (obj) params.vipLevel = obj.rid;
      } else if (privateForm[key]) {
        // 自定义字段
        if (Array.isArray(_form[key])) {
          if (privateForm[key].fieldType === 'radio')
            privateForm[key].answerValue = _form[key][0];
          else privateForm[key].answerValue = JSON.stringify(_form[key]);
        } else privateForm[key].answerValue = _form[key];

        params.answers.push(privateForm[key]);
      } else if (Array.isArray(_form[key]))
        params[key] = _form[key][0].url || '';
      else params[key] = _form[key] || '';
    });

    return params;
  }

  useEffect(() => {
    const request1 = getFieldList({
      settingType: 2,
      siteId: getLocalStorage('SITE_ID'),
    });

    const request2 = getSettingInfo({
      siteId: getLocalStorage('SITE_ID'),
    });

    const request3 = postOrderGet({
      siteId: getLocalStorage('SITE_ID'),
    });

    Promise.all([request1, request2, request3]).then((res) => {
      setForm((prevForm) => {
        const obj = {...prevForm};
        if (Array.isArray(res[0])) {
          res[0].forEach((item) => {
            // item.isInvalid = false;
            item.css = css`
              display: block;
            `;
            if (!item.answerValue) item.answerValue = '';

            item.key = `${item.fieldId}key`;

            item.custom = true;

            obj[`${item.fieldId}key`] = item;
          });
        }

        return obj;
      });

      setVipLevelItems(
        res[1]?.levels.map((i) => {
          return {itemName: i.levelName, rid: i.levelId};
        })
      );

      if (res[2]) {
        const keyMap = [
          'orgName',
          'orgLicenseNum',
          'vipName',
          'vipJob',
          'vipIdcard',
          'vipPhone',
          'vipWechat',
        ];
        keyMap.forEach((key) => {
          setValue(key, res[2][key]);
        });

        setValue('orgLicenseImage', [{url: res[2].orgLicenseImage}]);
        setValue('vipMemberImage', [{url: res[2].vipMemberImage}]);

        setValue('vipPhone', res[2].vipPhone || userInfo?.loginPhone);

        const obj = res[1]?.levels.find((item) => {
          return item.levelId === res[2].vipLevel;
        });
        if (obj) setValue('vipLevel', [obj.levelName]);
        res[2].answers?.forEach((item) => {
          const fieldObj = res[2].fileds?.find((i) => {
            return i.fieldId === item.fieldId;
          });
          const fieldType = fieldObj?.fieldType;
          if (fieldType === 'singleInput' || fieldType === 'multiInput') {
            setValue(`${item.fieldId}key`, item.answerValue);
          } else if (fieldType === 'radio') {
            setValue(
              `${item.fieldId}key`,
              item.answerValue ? [item.answerValue] : ''
            );
          } else if (fieldType) {
            setValue(
              `${item.fieldId}key`,
              item.answerValue ? JSON.parse(item.answerValue) : ''
            );
          }
        });

        setCheckStatus(res[2].checkStatus);
      }
    });

    getSiteTitle().then((res) => {
      setSiteTitle(res || '');
    });
  }, [setValue]);

  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>入会申请</NavTitle>
      </Navigation>
      <Main
        className='ApplicationPage'
        css={css`
          background-color: ${theme.colors.BLOCK};
          padding-bottom: 40px;
        `}
      >
        <FormList style={{padding: '10px 15px 30px'}}>
          <FormControl isRequired isInvalid={errors.orgName}>
            <FormTop>
              <ApplicationFormLabel
                htmlFor='orgName'
                style={{margin: 0, gap: '2px'}}
              >
                企业名称（公章）
              </ApplicationFormLabel>
              {errors.orgName && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.orgName.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='orgName'
                placeholder='请输入中文企业名称'
                type='text'
                {...register('orgName', {required: '请输入企业名称'})}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errors.orgLicenseNum}
            style={{marginTop: '20px'}}
          >
            <FormTop>
              <ApplicationFormLabel
                htmlFor='orgLicenseNum'
                style={{margin: 0, gap: '2px'}}
              >
                营业执照号
              </ApplicationFormLabel>
              {errors.orgLicenseNum && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.orgLicenseNum.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='orgLicenseNum'
                placeholder='请填写营业执照号'
                type='text'
                {...register('orgLicenseNum', {required: '请填写营业执照号'})}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errors.orgLicenseImage}
            style={{
              marginTop: '20px',
              width: '50%',
              display: 'inline-block',
              verticalAlign: 'top',
              paddingRight: '10px',
            }}
          >
            <FormTop>
              <ApplicationFormLabel
                htmlFor='orgLicenseImage'
                style={{margin: 0, gap: '2px'}}
              >
                上传营业执照
              </ApplicationFormLabel>
            </FormTop>
            <FormBottom>
              <FormImages
                {...register('orgLicenseImage', {required: '请上传营业执照'})}
                defaultImage={images.memberApply.file}
                setValue={(val) => setValue('orgLicenseImage', val)}
                value={watch('orgLicenseImage')}
              />
              {errors.orgLicenseImage && (
                <FormErrorMessage style={{marginTop: '5px'}}>
                  <FormTips>{errors.orgLicenseImage.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormBottom>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errors.vipMemberImage}
            style={{
              marginTop: '20px',
              width: '50%',
              display: 'inline-block',
              verticalAlign: 'top',
              paddingLeft: '10px',
            }}
          >
            <FormTop>
              <ApplicationFormLabel
                htmlFor='vipMemberImage'
                style={{margin: 0, gap: '2px'}}
              >
                企业会员代表照片
              </ApplicationFormLabel>
            </FormTop>
            <FormBottom>
              <FormImages
                {...register('vipMemberImage', {
                  required: '请上传企业会员代表照片',
                })}
                defaultImage={images.memberApply.man}
                setValue={(val) => setValue('vipMemberImage', val)}
                value={watch('vipMemberImage')}
              />
              {errors.vipMemberImage && (
                <FormErrorMessage style={{marginTop: '5px'}}>
                  <FormTips>{errors.vipMemberImage.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormBottom>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errors.vipName}
            style={{marginTop: '20px'}}
          >
            <FormTop>
              <ApplicationFormLabel
                htmlFor='vipName'
                style={{margin: 0, gap: '2px'}}
              >
                会员代表姓名
              </ApplicationFormLabel>
              {errors.vipName && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.vipName.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='vipName'
                placeholder='请输入会员代表姓名'
                type='text'
                {...register('vipName', {
                  required: '请输入会员代表姓名',
                })}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errors.vipJob}
            style={{marginTop: '20px'}}
          >
            <FormTop>
              <ApplicationFormLabel
                htmlFor='vipJob'
                style={{margin: 0, gap: '2px'}}
              >
                职务
              </ApplicationFormLabel>
              {errors.vipJob && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.vipJob.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='vipJob'
                placeholder='请输入职务'
                type='text'
                {...register('vipJob', {
                  required: '请输入职务',
                })}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errors.vipIdcard}
            style={{marginTop: '20px'}}
          >
            <FormTop>
              <ApplicationFormLabel
                htmlFor='vipIdcard'
                style={{margin: 0, gap: '2px'}}
              >
                身份证号
              </ApplicationFormLabel>
              {errors.vipIdcard && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>
                    {errors.vipIdcard.message || '请输入正确身份证格式'}
                  </FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='vipIdcard'
                placeholder='请输入身份证号'
                type='text'
                {...register('vipIdcard', {
                  required: '请输入身份证号',
                  pattern: IDENTITYCARD_REG,
                })}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errors.vipPhone}
            style={{marginTop: '20px'}}
          >
            <FormTop>
              <ApplicationFormLabel
                htmlFor='vipPhone'
                style={{margin: 0, gap: '2px'}}
              >
                手机
              </ApplicationFormLabel>
              {errors.vipPhone && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>
                    {errors.vipPhone.message || '请输入正确手机格式'}
                  </FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='vipPhone'
                placeholder='请输入手机'
                type='text'
                {...register('vipPhone', {
                  required: '请输入手机',
                  pattern: MYREG_REG,
                })}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={errors.vipWechat}
            style={{marginTop: '20px'}}
          >
            <FormTop>
              <ApplicationFormLabel
                htmlFor='vipWechat'
                style={{margin: 0, gap: '2px'}}
              >
                微信
              </ApplicationFormLabel>
              {errors.vipWechat && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.vipWechat.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>
            <FormBottom>
              <Input
                id='vipWechat'
                placeholder='请输入微信'
                type='text'
                {...register('vipWechat', {
                  required: '请输入微信',
                })}
                style={{
                  backgroundColor: '#fbfbfb',
                  height: '45px',
                }}
              />
            </FormBottom>
          </FormControl>

          {/* 自定义字段 */}
          {Object.keys(form).map((key) => {
            return (
              <FormControl
                isInvalid={errors[key]}
                isRequired={form[key].flagRequired}
                key={key}
                style={{marginTop: '20px'}}
              >
                <FormTop>
                  <ApplicationFormLabel
                    htmlFor={key}
                    style={{margin: 0, gap: '2px'}}
                  >
                    {form[key].fieldName}
                  </ApplicationFormLabel>
                  {errors[key] && (
                    <FormErrorMessage style={{margin: 0}}>
                      <FormTips>{errors[key].message}</FormTips>
                    </FormErrorMessage>
                  )}
                </FormTop>

                <FormBottom>
                  <FormBottomItem
                    fieldKey={key}
                    fieldTip={form[key].fieldTip}
                    isInvalid={form[key].flagRequired}
                    maxLimit={form[key].maxLimit}
                    ratio={form[key].ratio}
                    register={register}
                    setValue={(val) => setValue(key, val)}
                    type={form[key].fieldType}
                    value={watch(key)}
                    onClick={() => {
                      handleClickInput(
                        key,
                        form[key].items,
                        form[key].fieldType,
                        form[key].fieldName
                      );
                    }}
                  />
                </FormBottom>
              </FormControl>
            );
          })}

          <FormControl
            isRequired
            isInvalid={errors.vipLevel}
            style={{marginTop: '20px'}}
          >
            <FormTop>
              <ApplicationFormLabel
                htmlFor='vipLevel'
                style={{margin: 0, gap: '2px'}}
              >
                入会申请
              </ApplicationFormLabel>
              {errors.vipLevel && (
                <FormErrorMessage style={{margin: 0}}>
                  <FormTips>{errors.vipLevel.message}</FormTips>
                </FormErrorMessage>
              )}
            </FormTop>

            <FormTextCSS>本公司/本人自愿申请加入{siteTitle}，担任</FormTextCSS>

            <FormBottom
              css={css`
                margin: 10px 0;
              `}
            >
              <FormSelect
                {...register('vipLevel', {required: '请选择'})}
                fieldTip='请选择'
                value={watch('vipLevel')}
                onClick={() => {
                  handleClickInput(
                    'vipLevel',
                    vipLevelItems,
                    'radio',
                    '入会申请'
                  );
                }}
              />
            </FormBottom>

            <FormTextCSS>
              承认《{siteTitle}协会章程》，承诺在享受会员权利的同时，
              履行会员义务，维护协会的名誉和权益。
            </FormTextCSS>
          </FormControl>

          <DownLoadBox>
            <div className='left'>
              *请将本表单下载打印出来签名并盖章，扫描再上传文件
            </div>
            <Button className='right' onClick={handleSubmit(downLoad)}>
              下载
            </Button>
          </DownLoadBox>
        </FormList>

        <Checkbox
          css={css`
            margin: 0 0 20px 0;
            justify-content: start;
            padding: 0 15px;
          `}
          onClick={() => {
            setFinalChecked(!finalChecked);
          }}
        >
          <CheckboxSelect checked={finalChecked || false} />
          <FormTextCSS
            css={css`
              margin-left: 10px;
            `}
          >
            我已阅读并同意
            <NavLink
              css={css`
                color: ${theme.colors.PRIMARY};
                text-decoration: underline;
              `}
              to='/application-agreement'
            >
              入会协议
            </NavLink>
          </FormTextCSS>
        </Checkbox>

        <Box px='15px'>
          <Button onClick={handleSubmit(onSubmit)}>
            {loading && <Spinner size='md' />}
            上传文件并提交申请
          </Button>
        </Box>

        <Drawer
          finalFocusRef={btnRef}
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent css={DrawerContentCSS}>
            {/* <DrawerCloseButton /> */}
            <DrawerHeader
              css={css`
                padding: 19px 0 0 15px;
              `}
            >
              <div css={DrawerHeaderCSS}>{curSelectTitle}</div>
            </DrawerHeader>

            <DrawerBody
              css={css`
                padding: 2px 20px 0 30px;
              `}
            >
              {curItems.map((item, index) => (
                <Checkbox
                  css={css`
                    margin-top: 20px;
                  `}
                  key={index}
                  onClick={() => {
                    onClickCheck(item);
                  }}
                >
                  <CheckboxText>{item.itemName}</CheckboxText>
                  <CheckboxSelect checked={item.checked || false}>
                    {curFieldType === 'radio' && (
                      <RiCheckFill
                        css={css`
                          color: ${theme.colors.PRIMARY};
                          font-size: 16px;
                        `}
                      />
                    )}
                  </CheckboxSelect>
                </Checkbox>
              ))}
            </DrawerBody>

            <DrawerFooter
              css={css`
                padding: 0;
              `}
            >
              <Button borderRadius={0} onClick={handleConfirmSelect}>
                确定
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Main>
    </Container>
  );
}
