const {request} = require('../request');

// 新增报名
export function postActivityApplyList(data) {
  return request.post(`/xht/activity/apply/add`, data);
}

// 报名详情
export function postActivityApplyGet(data) {
  return request.post(`/xht/activity/apply/get`, data);
}

// 我的活动列表
export function getActivityMineList(data) {
  return request.post(`/xht/activity/list`, data);
}

// 新增签到
export function postActivityQdAdd(data) {
  return request.post(`/xht/activity/qd/add`, data);
}

// 活动详情
export function getActivityDetail(data) {
  return request.post(`/xht/api/activity/get`, data);
}

// 获取活动列表
export function getActivityList(data) {
  return request.post(`/xht/api/activity/list`, data);
}
