import {Box, SkeletonText, useToast} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState} from 'react';
import {RiArrowLeftCircleLine} from 'react-icons/ri';
import {useParams} from 'react-router-dom';

import {images} from '../../assets';
import {Image} from '../../components/Image';
import {Main} from '../../components/Main';
import {NavBack} from '../../components/Navigation';
import {localStorageKeys} from '../../constants/storageKeys';
import {
  getConcat,
  getConcatAdd,
} from '../../helpers/requestApi/xhtOrgVipController';
import {ProfileCard} from '../UserPage/ProfileCard';
import {
  BtnBox,
  Container,
  IconText,
  IconTextBox,
  ProfileBlock,
  ProfileField,
  ProfileGroup,
  ProfileTitle,
  TextBox,
} from './components';
const company_type = {
  1: '国企',
  2: '集体企业',
  3: '私营企业',
  4: '有限责任公司',
  5: '股份有限公司',
  6: '中外合资企业',
  7: '外商投资',
  8: '其他',
};

const ImageBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const NavBackCSS = css`
  position: absolute;
  left: 3px;
  top: 3px;
  height: auto;
  color: #fff;
  margin-left: 0;
  z-index: 1;
`;

export function AddressBookDetailPage() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    orgName: '',
    orgAddress: '',
    vipPhone: '',
    vipJob: '',
    orgType: '',
    fileds: [],
    flagConcat: false,
  });
  const toast = useToast();
  function submitCheck() {
    setBtnLoading(true);
    getConcatAdd({
      siteId: localStorage.getItem(localStorageKeys.SITE_ID),
      vipId: params.vipId,
    }).then((res) => {
      setBtnLoading(false);
      toast({
        description: '提交成功',
        duration: 3000,
        position: 'top',
        status: 'success',
      });
    });
  }
  function getUserInfo(data) {
    setLoading(false);
    const obj = {};
    for (const key in userInfo) {
      if (Object.hasOwnProperty.call(userInfo, key)) {
        if (key === 'fileds') {
          obj[key] = data[key] || [];
          obj[key].forEach((item) => {
            if (item.answerValue) {
              if (item.fieldType === 'checkbox')
                item.answerValue = JSON.parse(item.answerValue).join(',');

              if (item.fieldType === 'images' || item.fieldType === 'file')
                item.answerValue = JSON.parse(item.answerValue);
            }
          });
        } else {
          obj[key] = data[key];
        }
      }
    }
    setUserInfo(obj);
  }
  return (
    <Main className='AddressBookDetailPage'>
      <NavBack css={NavBackCSS}>
        <RiArrowLeftCircleLine size={30} />
      </NavBack>
      <ProfileCard
        getUserInfo={getUserInfo}
        userApi={getConcat}
        vipId={params.vipId}
      />
      <Box bg='#111' height='40px' />

      <Container>
        <SkeletonText isLoaded={!loading} noOfLines={10}>
          <IconTextBox>
            <IconText>
              <Image size={24} src={images.user.phone} />

              {
                <TextBox>
                  {userInfo.vipPhone}
                  {/* {userInfo.vipPhone &&
                    `${userInfo.vipPhone.substring(
                      0,
                      3
                    )}****${userInfo.vipPhone.substring(7, 11)}`} */}
                </TextBox>
              }

              {!userInfo.flagConcat && (
                <BtnBox isLoading={btnLoading} onClick={submitCheck}>
                  提交查看请求
                </BtnBox>
              )}
            </IconText>

            <IconText>
              <Image size={24} src={images.user.address} />

              <TextBox>{userInfo.orgAddress}</TextBox>
            </IconText>
          </IconTextBox>

          <ProfileBlock spacing={4}>
            <ProfileGroup>
              <ProfileTitle>单位性质</ProfileTitle>
              <ProfileField>{company_type[userInfo.orgType]}</ProfileField>
            </ProfileGroup>
            {userInfo.fileds.map(
              (item) =>
                item.flagAppShow &&
                item.answerValue &&
                item.answerValue.length && (
                  <ProfileGroup key={item.fieldId}>
                    <ProfileTitle>{item.fieldName}</ProfileTitle>
                    {item.fieldType === 'images' ? (
                      <ImageBox>
                        {item.answerValue.map((i, k) => {
                          return (
                            <Image
                              css={css`
                                margin-bottom: 2px;
                              `}
                              key={k}
                              src={i.url}
                              width='45%'
                            />
                          );
                        })}
                      </ImageBox>
                    ) : item.fieldType === 'file' ? (
                      item.answerValue.map((i, k) => {
                        return <ProfileField key={k}>{i.name}</ProfileField>;
                      })
                    ) : (
                      <ProfileField>{item.answerValue}</ProfileField>
                    )}
                  </ProfileGroup>
                )
            )}
          </ProfileBlock>
        </SkeletonText>
      </Container>

      {/* <Box my={4} px='15px'>
        <EditButton as={Link} to='/user/profile/edit'>
          修改资料
        </EditButton>
      </Box> */}
    </Main>
  );
}
