import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {size} from 'polished';

import {Image} from '../../components/Image';
import {theme} from '../../constants/theme';

export const BannerCSS = styled.section`
  padding: 20px 15px 15px;
`;

export function Banner({children}) {
  return <BannerCSS>{children}</BannerCSS>;
}

export const BannerTitle = styled.h2`
  font-size: 20px;
  font-family: ${theme.fonts.PINGFANG_SEMIBOLD};
  display: flex;
  align-items: center;
  /* gap: 5px; */
  margin-bottom: 5px;
`;
export const BannerLogo = styled(Image)`
  ${size(40)}
  border-radius: 50%;
  background-color: white;
  margin-right: 5px;
`;

export const BannerList = styled.div`
  height: 160px;
  border-radius: 6px;
  overflow: hidden;
  background-color: lightgray;
`;
export const BannerItem = styled.div`
  height: 160px;
  width: 100%;
`;

export const MenuList = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 20px;
`;
export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const MenuItemIcon = styled(Image)`
  ${size(50)}
  border-radius: 5px;
  margin-bottom: 8px;
`;
export const MenuItemText = styled.span`
  font-size: 12px;
`;

export const FeedTitleRow = styled.div(
  (props) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 20px;
    background: url(${props.image}) no-repeat left/contain;
    font-size: 12px;
    color: #2d6dad;
    margin-bottom: 20px;
  `
);
