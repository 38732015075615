import {getLocalStorage, localStorageKeys} from '../../constants/storageKeys';

const {request} = require('../request');

// 获取站点名称
export function getSiteTitle() {
  return request.get(`/user/site/title/${getLocalStorage('SITE_ID')}`);
}

// 获取站点配置
export function getSiteConfig() {
  return request.get(
    `/user/site/${localStorage.getItem(localStorageKeys.SITE_ID)}`
  );
}
