import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  SkeletonText,
} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {images} from '../../assets';
import {Main} from '../../components/Main';
import {NavBack, Navigation, NavTitle} from '../../components/Navigation';
import {getLocalStorage} from '../../constants/storageKeys';
import {userFeePayRecord} from '../../helpers/requestApi/xhtOrgFeeController';
import {useGetUserInfo} from '../../hooks/useGetUserInfo';
import {Container} from '../Layout/components';
export const FeeCard = styled.div`
  background-color: #171717;
  padding: 22px 0 60px 0;
  background: url(${images.user.feeBg}) no-repeat top left/100% 100%;
`;
export const Card = styled.div`
  height: 181px;
  margin-left: 37px;
  padding: 8px 0 0 18px;
`;
const NameText = styled.p`
  padding-bottom: 14px;
  font-size: 16px;
  color: #595959;
`;
const CardBottom = styled.div`
  padding-right: 14px;
`;
const TopTime = styled.div`
  color: #2d6dad;
  font-size: 13px;
  padding-top: 9px;
  padding-left: 15px;
`;
const TimeText = styled.div`
  color: #595959;
  font-size: 12px;
  line-height: 17px;
`;
const TimeDay = styled.div`
  font-size: 35px;
  line-height: 44px;
  padding-bottom: 12px;
`;
const BottomTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-top: 5px;
`;
const TimeP = styled.div`
  font-size: 12px;
`;
const FeeRecord = styled.div`
  background: #fff;
  transform: translateY(-45px);
  margin: 0 15px;
  opacity: 0.99;
  border-radius: 7px 7px 0px 0px;
`;
const Gradient = styled.div`
  height: 13px;
  background: linear-gradient(
    180deg,
    #242424 0%,
    #242424 18%,
    rgba(36, 36, 36, 0.67) 52%,
    rgba(36, 36, 36, 0) 100%
  );
  border-radius: 5px 5px 0px 0px;
  opacity: 0.99;
`;
const RecordTable = styled.div`
  font-size: 13px;
  padding-bottom: 15px;
`;
const FeeTableTitle = styled.div`
  font-weight: bold;
  color: #212121;
  font-size: 16px;
  text-align: center;
  padding: 5px 0;
`;
export function UserFeeIndexPage() {
  const navigate = useNavigate();
  const {userInfo} = useGetUserInfo();

  const orgName = userInfo?.orgName || '--';

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [feeInfo, setFeeInfo] = useState({
    vipDay: 0,
    status: 0,
    msg: '',
  });
  const [feeCardBg, setFeeCardBg] = useState(images.user.feeCardBg);
  const [feeCardBgIcon, setFeeCardBgIcon] = useState(images.user.feeCardBgIcon);
  useEffect(() => {
    userFeePayRecord({
      siteId: getLocalStorage('SITE_ID'),
      // orgLicenseNum,
    }).then((res) => {
      const {list, ...reset} = res;
      setFeeCardBg(
        reset.status > 20 ? images.user.feeCardBgActive : images.user.feeCardBg
      );
      setFeeCardBgIcon(
        reset.status > 20
          ? images.user.feeCardBgActiveIcon
          : images.user.feeCardBgIcon
      );
      setFeeInfo(reset);
      setTableData(list || []);
      setLoading(false);
    });
  }, []);
  return (
    <Container>
      <Navigation>
        <NavBack />
        <NavTitle>会费缴纳</NavTitle>
      </Navigation>
      <Main className='UserFeeIndexPage'>
        <FeeCard>
          <Card
            css={css`
              background: url(${feeCardBg}) no-repeat right top / 100% 100%;
            `}
          >
            <NameText>{orgName}</NameText>
            <CardBottom>
              <TopTime
                css={css`
                  background: url(${feeCardBgIcon}) no-repeat left top / 81px
                    82px;
                `}
              >
                <TimeText>入会天数</TimeText>
                <TimeDay>{feeInfo.vipDay || 0}</TimeDay>
              </TopTime>
              <BottomTime>
                <Button
                  borderColor='#2d6dad'
                  borderRadius='20px'
                  color='#2d6dad'
                  css={css`
                    padding: 0 13px;
                  `}
                  isDisabled={feeInfo.status > 20}
                  variant='outline'
                  onClick={() => {
                    navigate('/user/fee/info');
                  }}
                >
                  缴纳会费
                </Button>
                <TimeP
                  css={css`
                    color: ${feeInfo.status === 21 ? '#2d6dad' : '#A7A7A7'};
                  `}
                >
                  {feeInfo.msg}
                </TimeP>
              </BottomTime>
            </CardBottom>
          </Card>
        </FeeCard>
        <FeeRecord>
          <Gradient />
          <RecordTable>
            <FeeTableTitle>缴费记录</FeeTableTitle>
            <SkeletonText isLoaded={!loading}>
              <Table variant='simple'>
                {!tableData?.length && (
                  <TableCaption>暂无缴费记录~~</TableCaption>
                )}
                <Thead>
                  <Tr>
                    <Th>缴费时间</Th>
                    <Th>会员时间</Th>
                    <Th>缴费金额</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {tableData.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.createDate}</Td>
                      <Td>
                        {item.feeStartDate}
                        <br />
                        {item.feeEndDate}
                      </Td>
                      <Td>{item.fee}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </SkeletonText>
          </RecordTable>
        </FeeRecord>
      </Main>
    </Container>
  );
}
