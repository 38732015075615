import {Skeleton} from '@chakra-ui/react';
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {useState} from 'react';

import {
  uploadOpenFile,
  uploadOpenImage,
} from '../helpers/requestApi/commonController';
import {useSelectFiles} from '../hooks/useSelectFiles';

export const UploadFileCSS = styled.div(
  ({width, height = '100px'}) => css`
    width: ${width ?? '100%'};
    height: ${height};
    background-color: #fbfbfb;
    border-radius: 5px;
    border: 1px solid #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `
);

export function UploadFile({
  children,
  setValue,
  width,
  height,
  type = 'image',
}) {
  const {selectFiles} = useSelectFiles();
  const [isLoaded, setIsLoaded] = useState(true);

  function handleClick() {
    selectFiles().then(([file]) => {
      const formData = new FormData();
      formData.set('files', file);

      let api = null;

      if (type === 'image') api = uploadOpenImage;
      else api = uploadOpenFile;
      setIsLoaded(false);
      api(formData)
        .then((res) => {
          setIsLoaded(true);
          setValue(`${res}`, file.name);
        })
        .catch((err) => {
          setIsLoaded(true);
        });
    });
  }

  return (
    <Skeleton
      css={css`
        width: 100%;
      `}
      isLoaded={isLoaded}
    >
      <UploadFileCSS height={height} width={width} onClick={handleClick}>
        {children}
      </UploadFileCSS>
    </Skeleton>
  );
}
