import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {RiArrowLeftSLine} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

import {theme} from '../constants/theme';
import {fontsCSS} from '../helpers/css';

const Container = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  position: relative;
  background-color: ${theme.colors.BLOCK};
`;

// NavBack
export function NavBack(props) {
  const navigate = useNavigate();

  return (
    <button
      className='goBack'
      css={css`
        height: 100%;
        position: absolute;
        margin-left: -10px;
      `}
      onClick={(ev) => {
        navigate(-1);
        if (props.onClick) props.onClick(ev);
      }}
      {...props}
    >
      {props.children ? props.children : <RiArrowLeftSLine size={26} />}
    </button>
  );
}

export const NavTitle = styled.p`
  font-size: 16px;
  ${fontsCSS.semibold};
  color: #2a2a2a;
  line-height: 22px;
  flex: 1;
  text-align: center;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function Navigation({children}) {
  return <Container>{children}</Container>;
}
